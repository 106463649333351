import React, {useContext, useState} from 'react'
import { useTranslation } from 'react-i18next';
import './FunctionManagementAccordion.scss';
import { UserContext } from "../../appContext"
import ConfirmationDialog from '../../components/ConfirmationDialog';

type FuctionManagementDetailsPropsType = {
    deleting: (e: string) => void
    detailsData: any,
}
const FuctionManagementDetails = (props: FuctionManagementDetailsPropsType) => {
    const { detailsData, deleting } = props
    const { t } = useTranslation();
    const { permissions, role } = useContext(UserContext);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    return (
        <div className="details">
            <div className="form sdds-body-02">
                <div className="title sdds-headline-06">Base URL</div>
                <p>{detailsData.baseUri}</p>
                <div className="title sdds-headline-06">{t("TS_core:Name")}</div>
                <p>{detailsData.name}</p>
                <div className="title sdds-headline-06">Function Parameters</div>
                {(!(detailsData?.contextSpec?.functionParameters) || (detailsData?.contextSpec?.functionParameters.length === 0)) && <p>N/A</p>}
                {detailsData?.contextSpec?.functionParameters.map((i: any, index: number) => {
                    return (
                        <p key={index}>{i}</p>
                    )
                })}
                <div className="title sdds-headline-06">Pre condition</div> 
                <p>{detailsData.preCondition ? detailsData.preCondition : 'N/A'}</p>
            </div>
            <div className="">
                <div className="title sdds-headline-06">Function ID</div>
                <p>{detailsData.id}</p>
                <div className="title sdds-headline-06">{t("TS_core:Description")}</div>
                <p>{detailsData.description ? detailsData.description : '--'}</p>
                <div className="title sdds-headline-06">Assert Parameters</div>
                {(!(detailsData?.contextSpec?.assertParameters) || (detailsData?.contextSpec?.assertParameters.length === 0)) && <p>N/A</p>}
                {detailsData?.contextSpec && detailsData.contextSpec.assertParameters.map((i: any, index: number) => {
                    return (
                        <p key={index }>{i}</p>
                    )
                })}
                <div className="title sdds-headline-06">Post condition</div>
                <p>{detailsData.preCondition ? detailsData.preCondition : 'N/A'}</p>
            </div>
            {role === 'Admin' && permissions?.developer && <div className="delete">
                <button className='sdds-btn sdds-btn-secondary sdds-btn-sm' title={t('TS_core:Deregister')} onClick={() => setShowDeleteDialog(true)}>{t('TS_core:Deregister')}</button>
                {
                    showDeleteDialog &&
                    <ConfirmationDialog title={t("TS_core:ConfirmDeleteTitle")}
                        confirmationMsg={t("TS_core:ConfirmDeleteMessage")}
                        onAction={() => { deleting(detailsData.id);
                                            setShowDeleteDialog(false);
                                        console.log(detailsData.id)}
                                 }
                        primaryActionBtn={t("TS_core:Deregister")}
                        secondaryActionBtn={t("TS_core:Cancel")}
                        isDelete
                        onClose={() => setShowDeleteDialog(false)} />
                }
            </div>}
        </div>

    );
}
export default FuctionManagementDetails;