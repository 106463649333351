/* eslint-disable quote-props */
type sectionRoleMapperType = {
    [key: string]: string[]
  }; 
  
  // Map the exact permissions for different sections in the module
  const sectionRoleMapper: sectionRoleMapperType = {
    'Service Troubleshooting': ['Admin','GTS','LoTH','FQ','Assistance','Basic', 'BasicLimited'],
    'Fault Guide Management': ['Admin', 'GTS'],
    'Function Management': ['Admin'],
    'Package Management': ['Admin']    
  };
  
  const getSupportedRolesForSections = (section: string) => sectionRoleMapper[section] || []; 
  
  export {
    getSupportedRolesForSections
  };
  