import { AxiosPromise } from 'axios';
import { csttFacadeApi } from '../csttFacadeApi';

const addFunctionToPackage = (payload: AddFunctionPayloadType): AxiosPromise<TsFunction> => csttFacadeApi.post('/csttfacade/tsguide/v1/packages/functions', payload)
const getAllPackages = (): AxiosPromise<PackageType> => csttFacadeApi.get(`/csttfacade/tsguide/v1/packages/`);
const getPackageById = (id: string): AxiosPromise<PackageType> => csttFacadeApi.get(`/csttfacade/tsguide/v1/packages/${id}`);
const removeFunctionFromPackage = (packageId: string, functionId: string): AxiosPromise => csttFacadeApi.delete(`/csttfacade/tsguide/v1/packages/${packageId}/functions/${functionId}`)
const deletePackage = (packageId: string): AxiosPromise => csttFacadeApi.delete(`/csttfacade/tsguide/v1/packages/${packageId}`)
const savePackage = (payload: PackageUpdatePayloadType): AxiosPromise => csttFacadeApi.put(`/csttfacade/tsguide/v1/packages/${payload.id}`, payload)
const addNewPackage = (payload: NewPackageType): AxiosPromise => csttFacadeApi.post(`/csttfacade/tsguide/v1/packages`, payload)

export { getAllPackages, addFunctionToPackage, getPackageById, removeFunctionFromPackage, deletePackage, savePackage, addNewPackage }