import React, { useState } from 'react'
import { keys } from 'lodash';
import WarningSvg from '../../icons/Warning';
import './FunctionComponent.scss'

type FunctionComponentPropType = {
  data: PackageFunctionType
  onAddToPackageClick: Function,
  onDeleteFromPackageClick: Function,
  isReadonly?: boolean
}

const FunctionComponent = ({ data, onAddToPackageClick, isReadonly, onDeleteFromPackageClick }: FunctionComponentPropType) => {
  const [assertParams, setAssertParams] = useState<any>({})
  const [errors, setErrors] = useState<any>({})


  const onAddToStepClick = (step: number) => {
    if (data?.contextSpec?.assertParameters && data?.contextSpec?.assertParameters.length > 0) {
      data?.contextSpec?.assertParameters.forEach((parameter) => {
        if (!assertParams[parameter]) {
          setErrors({ ...errors, [parameter]: "error" });
        } else {
          setErrors({ ...errors, [parameter]: "" });
        }
      })
    }
    if (!hasErrors()) {
      onAddToPackageClick({
        functionId: data?.id,
        assertParameters: assertParams,
        step: step
      })
    }
  }

  const hasErrors = () => {
    let hasError = false;
    if (data?.contextSpec?.assertParameters && data?.contextSpec?.assertParameters.length > 0) {
      data?.contextSpec?.assertParameters.forEach((parameter) => {
        if (errors[parameter] && errors[parameter] !== "") {
          hasError = true;
        }
      });
    }
    return hasError;
  }

  const onParamValueChange = (param: string, paramValue: string) => {
    if (assertParams === null) {
      setAssertParams({ [param]: paramValue });
    } else {
      setAssertParams({ ...assertParams, [param]: paramValue });
    }
  }
  const onHoverInfoIcon = (e: React.MouseEvent<HTMLInputElement>) => {
    let rectangle: DOMRect = e.currentTarget.getBoundingClientRect();
    if (e.currentTarget.parentElement) {
      let parentRect = e.currentTarget.parentElement.getBoundingClientRect();
      let tooltip = (e.currentTarget.parentElement.children[1] as HTMLElement)
      if (tooltip) {
        let tooltipBounds = tooltip.getBoundingClientRect();
        tooltip.style.top = (0 - Math.ceil(tooltipBounds.height / 2)) + "px";
        tooltip.style.left = Math.ceil((rectangle.left - parentRect.x) + rectangle.width) + "px";
      }
    }
  }

  return <>
    <div className="function-container sdds-u-p2" key={data.id}>
      <div className="func-data" >
        <div className="sdds-headline-06 func-name">{data.name}
          {!data.contextSpec && !isReadonly && <>
            <WarningSvg color="var(--sdds-warning)" onMouseOver={onHoverInfoIcon}></WarningSvg>
            <div className="sdds-tooltip sdds-tooltip-undefined">Unreachable Function
              <p>This function is not reachable at the moment. If you know that this is a temporary outage you can still add it to the Package.</p>
            </div>
          </>}
        </div>
        <div className="sdds-headline-07 sdds-u-mt2">Function ID</div>
        <div className="sdds-detail-03 ">{data.id}</div>
        <div className="sdds-headline-07 sdds-u-mt2">Base URL</div>
        <div className="sdds-detail-03 base-uri">{data.baseUri}</div>
      </div>
      {isReadonly && <div className="sdds-u-ml1">
        <span className="sdds-modal-btn func-del" role="button" onClick={() => onDeleteFromPackageClick(data.id)} onKeyUp={() => onDeleteFromPackageClick(data.id)}></span>
      </div>}
      {!isReadonly && <div className="sdds-u-ml1 func-actions sdds-u-p2" >
        <button type='button' className='sdds-btn sdds-btn-secondary sdds-btn-sm' onClick={() => onAddToStepClick(2)}>Add to Step 2</button>
        <button type='button' className='sdds-btn sdds-btn-secondary sdds-btn-sm sdds-u-mt3' onClick={() => onAddToStepClick(3)}>Add to Step 3</button>
      </div>}
    </div>
    {data?.contextSpec?.assertParameters && data?.contextSpec?.assertParameters.length > 0 && <div className="func-assert-params  sdds-u-pr2">
      <div className="sdds-headline-06 sdds-u-mt2 assert-param-lbl">Assert Parameters</div>
      <div>
        {!isReadonly && data?.contextSpec?.assertParameters.map((param: string) => {
          return <div className="assert-param" key={param} >
            <strong>{param} : </strong>
            {<input type="text" className={errors[param]} onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onParamValueChange(param, evt.target.value)} />}
          </div>
        })}

        {isReadonly && data?.assertParameters && keys(data?.assertParameters).map((param: string) => {
          return <div className="assert-param" key={param} >
            <strong className='sdds-headline-7'>{param} : </strong>
            <span className="sdds-detail-02">{data?.assertParameters[param]}</span>
          </div>
        })}
      </div>
    </div>}
  </>;
}

export default FunctionComponent;