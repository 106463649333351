import { Spinner } from '@cd/sdds-common-components-react';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import SearchBar from '../SearchBar';
import { filter } from 'lodash';

type PackageListingType = {
  packages: Array<PackageType>
  isPakageListApiLoading: boolean
  onSelectPackage: Function
  selectedPackage: PackageType
}
const PackageListing = ({ packages, selectedPackage, isPakageListApiLoading, onSelectPackage }: PackageListingType) => {
  const { t } = useTranslation();
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const onSearch = (searchedText: string) => {
    setSearchText(searchedText);
  }

  const closeSearch = () => {
    setShowSearchBar(false);
    setSearchText("");
  }
  const onAddPackageClick = () =>{
    onSelectPackage({
      name:"",
      functions:[],
      type:"Equipment",
      id:null,
      roles:[]
    });
  }

  return <>
    <div className="pkg-left-panel">
      <div className="top-bar sdds-u-p2">
        <div className="sdds-headline-06">{t("Service Troubleshooting Packages")}</div>
        <button className='sdds-btn sdds-btn-sm sdds-btn-primary add-package-btn' onClick={() => onAddPackageClick()}>Add Package</button>
      </div>
      <div className="search-bar sdds-u-p2">
        <div className="sdds-headline-06">{t("TS_core:SavedPackages")}</div>
        <div className="search-component">
          {showSearchBar && <SearchBar disabled={false} setSearchedValue={onSearch}  ></SearchBar>}
          {showSearchBar && <div className='close-search' role='button' onClick={() => closeSearch()} onKeyPress={() => closeSearch()} ><img src='/assets/icons/CloseIcon.svg' alt={t('Close search')} title={t('Close search')} /></div>}
          {!showSearchBar && <div className="search-icon" role="button" onClick={() => setShowSearchBar(true)} onKeyPress={() => setShowSearchBar(false)}> <img src="/assets/icons/Search.svg" alt="Search Packages" title="Search Package" /> </div>}
        </div>
      </div>
      <div className="pkg-listing">
        {isPakageListApiLoading && <Spinner />}
        {!isPakageListApiLoading && packages && packages.length > 0 && (searchText !== "" ? filter(packages, o => o.name.toLowerCase().includes(searchText.toLowerCase())) : packages).map((pakage) => {
          return <div className={`pkg-row sdds-u-p2 ${pakage.id === selectedPackage?.id ? "selected" :""}`} role='button' onClick={() => onSelectPackage(pakage)} onKeyPress={() => onSelectPackage(pakage)} key={pakage.id}>
            <div className="pkg-name sdds-detail-02">
              {pakage.name}
            </div>
            <div className="pkg-type-icon">
              {pakage.type === 'Equipment' && <img src="assets/icons/truckIcon.svg" alt=" " />}
              {pakage.type === 'Customer' && <img src="assets/icons/ProfileIcon.svg" alt=" " />}
            </div>
          </div>
        })}
      </div>
    </div>
  </>;
}

export default PackageListing;