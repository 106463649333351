import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from "../../redux/store/hooks";
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import orderBy from "lodash/orderBy";
import { Spinner, Toast, useToast } from '@cd/sdds-common-components-react';
import FaultCode from '../../components/FaultCode';
import FaultGuideForm from '../../components/FaultGuideForm';
import SearchBar from '../../components/SearchBar';
import {
    _updateAllGuides, _updateAllTsFunctions, _updateSearchedGuide, _updateSelectedGuideDto, _updateSelectedUserAction, _saveGuide,
    _updateGuide, _deleteGuide, _updateCUDApiResult
} from "../../redux/store/faultGuideManagement";
import './FaultGuideManagement.scss';

type FaultGuideManagementProps = {
    developerAccess: boolean
}

const FaultGuideManagement = (props: FaultGuideManagementProps) => {
    const { developerAccess } = props;
    const { i18n, t } = useTranslation();
    const [displayedTableData, setDisplayedTableData] = useState<TsFaultGuides>([]);
    const [tsFunctions, setTsFunctions] = useState<Array<FunctionType>>([]);
    const [functionIdsToNames, setFunctionIdsToNames] = useState<string>("");
    const [defaultOptions, setDefaultOptions] = useState<string>("");
    const [validationError, setValidationError] = useState<TextfieldErrorType>('success');
    const [lastActionTaken, setLastActionTaken] = useState<GuideActionType>('Default');
    const { toastQueue, addToast } = useToast();
    const dispatch = useAppDispatch();
    const faultGuideManagement: FaultGuideManagementState = useAppSelector<FaultGuideManagementState>(state => state.faultGuideManagement)
    const [search, setSearch] = useState<string>(faultGuideManagement.searchedGuide);
    const [hideSearchBar, setHideSearchBar] = useState<boolean>(true);
    const [hideOnLanding, setHideOnLanding] = useState<boolean>(true);

    //Get functions selected in the form
    const getSelectedFunctions = () => {
        var selectedFunctions = document?.getElementById("multi-select-demo")?.getAttribute("multi-selected-values");
        var selectedFunctionsNoLeftBracket = selectedFunctions?.replace("[", "");
        var selectedFunctionsNoBrackets = selectedFunctionsNoLeftBracket?.replace("]", "");
        var selectedValuesCleaned = selectedFunctionsNoBrackets?.replaceAll('"', "");
        var selectedSplittedValues = selectedValuesCleaned?.split(",");
        if (selectedSplittedValues === undefined || (selectedSplittedValues.length === 1 && selectedSplittedValues[0] === ''))
            selectedSplittedValues = undefined;
        return selectedSplittedValues;
    }

    // Get all field values from form
    const getFieldValues = (status: TranslationType) => {
        const tsGuideDto: TsGuideDto = {
            id: document?.getElementById('code')?.getAttribute('value'),
            description: document?.getElementById('description')?.getAttribute('value'),
            cause: document?.getElementById('cause')?.getAttribute('value'),
            action: document?.getElementById('action')?.getAttribute('value'),
            functions: getSelectedFunctions(),
            status: status
        };
        return tsGuideDto;
    }

    //form cancel action
    const onCancel = (userAction: FormStateType, doc: Document) => {
        if (userAction === 'create') {
            dispatch(_updateSelectedUserAction('hide'));
        } else
            dispatch(_updateSelectedUserAction('view'));
    }

    //form save action
    const onSave = (action: GuideActionType, status: TranslationType) => {
        setLastActionTaken(action);

        if (action === 'Create') {
            const format: RegExp = /^\d\d\d-\d\d-\d\d-\d\d$/;
            let faultCode = document?.getElementById("code")?.getAttribute("value");
            //Validation of fault code input
            if (faultCode && faultCode !== '') {
                if (format.test(document?.getElementById("code")?.getAttribute("value") || '')) {
                    setValidationError('success');
                    dispatch(_saveGuide(getFieldValues(status), i18n.language));
                }
                else {
                    setValidationError('error');
                    return;
                }
            } else return;
        }
        if (action === 'Update') {
            dispatch(_updateGuide(getFieldValues(status), i18n.language));
        }
        dispatch(_updateSelectedUserAction('hide'));
    }

    //guide delete action
    const onDelete = () => {
        setLastActionTaken('Delete');
        dispatch(_deleteGuide(faultGuideManagement.selectedGuideDto?.id, i18n.language));
        dispatch(_updateSelectedUserAction('hide'));
        dispatch(_updateSelectedGuideDto({
            "status": 'Draft',
            "action": "",
            "cause": "",
            "description": "",
            "functions": [],
            "id": ""
        }));
    }

    //sorts & filters table data based on existing search selection
    const sortedFilteredTableData = (): TsFaultGuides => {
        return orderBy(faultGuideManagement.allGuides, ['id'], ['asc']).filter((obj) => {
            return (
                obj?.id.indexOf(faultGuideManagement.searchedGuide) !== -1 ||
                obj?.description.toLowerCase().indexOf(faultGuideManagement.searchedGuide.toLowerCase()) !== -1
            );
        });
    }

    //updates the selected guide with latest values
    const getNewData = () => {
        dispatch(_updateSelectedGuideDto((faultGuideManagement.allGuides.filter((obj: TsGuideDto) => {
            return obj?.id === faultGuideManagement.selectedGuideDto.id
        })[0])));
    }

    //on selecting a guide, show form view filled with guide details 
    const showGuide = (index: number) => {
        setHideOnLanding(false)
        dispatch(_updateSelectedUserAction('view'));
        dispatch(_updateSelectedGuideDto(displayedTableData[index]));
    }


    //on load fetch all guides & functions
    useEffect(() => {
        if (faultGuideManagement.allGuides.length === 0) {
            dispatch(_updateAllGuides(i18n.language));
            dispatch(_updateAllTsFunctions());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    useEffect(() => {
        dispatch(_updateAllGuides(i18n.language));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    //sort table after every reload of data & apply existing filter
    useEffect(() => {
        if (lastActionTaken === 'Default' && !faultGuideManagement.faultGuidesApiLoading)
            setDisplayedTableData(sortedFilteredTableData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faultGuideManagement.faultGuidesApiLoading]);

    //get updated values for the selected guide after table reload
    useEffect(() => {
        if (displayedTableData.length === 0) return;
        if (faultGuideManagement.selectedGuideDto.id !== '') {
            getNewData();
            dispatch(_updateSelectedUserAction('view'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedTableData]);

    //add toast after create, update or delete action dynamically
    useEffect(() => {
        if (faultGuideManagement.CUDApiResult === 'Default') return;
        addToast({ title: t(`${lastActionTaken + ' ' + faultGuideManagement.CUDApiResult} `), text: '', type: `${faultGuideManagement.CUDApiResult === 'succeeded' ? 'success' : 'error'}` });
        setLastActionTaken('Default');
        dispatch(_updateCUDApiResult('Default'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faultGuideManagement.CUDApiResult]);

    //reset selected data if user action is create
    const onAddGuideClick = () => {
        dispatch(_updateSelectedUserAction('create'));
        dispatch(_updateSelectedGuideDto({
            "status": 'Draft',
            "action": "",
            "cause": "",
            "description": "",
            "functions": [],
            "id": ""
        }));
        setHideOnLanding(false);
    }

    //when search term changes, store the searched value
    useEffect(() => {
        dispatch(_updateSearchedGuide(search));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    //when search bar is closed, reset search term
    useEffect(() => {
        if (hideSearchBar)
            setSearch('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hideSearchBar]);

    //filter table data based on search
    useEffect(() => {
        setDisplayedTableData(sortedFilteredTableData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faultGuideManagement.searchedGuide])

    //processing ts functions before displaying
    useEffect(() => {
        const functionObjects = _.map(faultGuideManagement.tsFunctions, (obj, key) => {
            var aFunction = { value: "", label: "" };
            aFunction.value = obj.id;
            aFunction.label = obj.name;
            return aFunction
        });
        setTsFunctions(functionObjects);
    }, [faultGuideManagement.tsFunctions]);

    // Set default options for functions field
    useEffect(() => {
        var functions = [''];
        functions.splice(0);
        _.map(faultGuideManagement.selectedGuideDto.functions, (functionId) => {
            var foundFunction = tsFunctions.find(element => element.value === functionId);
            if (foundFunction === undefined)
                return "";
            functions.push(foundFunction.value);
            return functions;
        });

        var defaultOptions = "\"[";
        functions.forEach(f => {
            defaultOptions += "'" + f + "',";
        });

        if (functions.length < 1) {
            setDefaultOptions("");
            return;
        }
        defaultOptions = defaultOptions.substring(0, defaultOptions.lastIndexOf(","));
        defaultOptions += "]\"";
        setDefaultOptions(defaultOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tsFunctions, faultGuideManagement.selectedGuideDto.id]);

    // Map functionId to function name
    useEffect(() => {
        var funcIds = "";
        _.map(faultGuideManagement.selectedGuideDto.functions, (id) => {
            var funcType = tsFunctions.find(element => element.value === id);
            if (funcType !== undefined) {
                funcIds += funcType.label + ", ";
            }
        });
        funcIds = funcIds.slice(0, funcIds.length - 2);
        setFunctionIdsToNames(funcIds);
    }, [tsFunctions, faultGuideManagement.selectedGuideDto.functions]);


    return (<div className="fault-guide-container">
        <div>
            <div className='table-title sdds-headline-06'>
                <div className='title'>{t('TS_temp:ServiceTroubleshootingFaultGuides')}</div>
                {!hideSearchBar && <div className='search-bar'> <SearchBar disabled={faultGuideManagement.CUDApisLoading || faultGuideManagement.faultGuidesApiLoading} setSearchedValue={setSearch} /> </div>}
                {hideSearchBar && <div className='search-icon' role='button' onClick={() => setHideSearchBar(!hideSearchBar)} onKeyPress={() => setHideSearchBar(!hideSearchBar)}> <img src='../../../../assets/icons/Search.svg' alt={t('Search in table')} title={t('Search in table')} /> </div>}
                {!hideSearchBar && <div className='close-search' role='button' onClick={() => setHideSearchBar(!hideSearchBar)} onKeyPress={() => setHideSearchBar(!hideSearchBar)} ><img src='../../../../assets/icons/CloseIcon.svg' alt={t('Close search')} title={t('Close search')} /></div>}
                <button disabled={faultGuideManagement.userAction === 'create'} className='sdds-btn sdds-btn-primary sdds-btn-sm createBtn' onClick={() =>  onAddGuideClick()}>
                    {t("TS_core:AddGuide")}
                </button>
            </div>
            <div className='table-headers sdds-headline-07'>
                <div className='fault-code-column'>{t("TS_core:Code")}</div>
                <div className='fault-description-column'>{t('TS_core:Description')}</div>
            </div>
            <div className='fc-table-container'>
                {!faultGuideManagement.faultGuidesApiLoading && displayedTableData.map((data, index) => {
                    return <FaultCode code={data?.id || ''} description={data?.description || ''} showDetails={() => showGuide(index)} key={index} />
                })}
                {faultGuideManagement.faultGuidesApiLoading && <Spinner />}
            </div>
        </div>
        {!faultGuideManagement.CUDApisLoading &&
            faultGuideManagement.userAction !== 'hide' && !hideOnLanding &&
            <FaultGuideForm
                faultGuide={faultGuideManagement.selectedGuideDto}
                userAction={faultGuideManagement.userAction}
                getFieldValues={getFieldValues}
                onCancel={onCancel}
                onSave={onSave}
                onDelete={onDelete}
                validationError={validationError}
                tsFunctions={tsFunctions}
                functionIdsToNames={functionIdsToNames}
                tsFunctionNames={defaultOptions}
                developerAccess={developerAccess}
            />}
        <Toast toastQueue={toastQueue} />
    </div>)
}

export default FaultGuideManagement;