
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { getGuideByFaultCode } from '../../adapters/TsGuide'

type FaultCodeActionTabsProps = {
  faultCode?: string
  testLog: any[]
}

const FaultCodeActionTabs = ({ faultCode, testLog }: FaultCodeActionTabsProps) => {

  const { i18n, t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [guideData, setGuideData] = useState<TsGuide>(null);
  useEffect(() => {
    getGuideByFaultCode(faultCode as string, i18n.language).then((guideResponse) => {
      setGuideData(guideResponse.data);
    }).catch((err: Error) => { })
  }, [faultCode, i18n.language])

  return <div className="sdds-inline-tabs sdds-inline-tabs-onwhite">
    <nav className="sdds-inline-tabs-header">
      <div className="sdds-inline-tabs-wrapper">
        <button className={`sdds-inline-tabs--tab ${activeTabIndex === 0 ? 'sdds-inline-tabs--tab__active' : ''}`} onClick={() => { setActiveTabIndex(0) }} >
          <span>{t("TS_core:FaultCodeDetails")}</span>
        </button>
        <button className={`sdds-inline-tabs--tab ${activeTabIndex === 1 ? 'sdds-inline-tabs--tab__active' : ''}`} onClick={() => { setActiveTabIndex(1) }}  >
          <span>{t("TS_core:TestLog")}</span>
        </button>
      </div>
    </nav>
    <div className="sdds-inline-tabs-main">

      <div className={`tab-panel ${activeTabIndex === 0 ? 'active' : ''}`} key="tab1">
        <div className='tab-box' >
          <h6 className='sdds-headline-06'>{t('TS_core:Description')} :</h6>
          <span>{guideData?.description}</span>
        </div>
        <div className='tab-box' >
          <h6>{t("TS_core:PossibleCauses")} :</h6>
          <span>{guideData?.cause}</span>
        </div>
        <div className='tab-box' >
          <h6>{t("TS_core:Action")} :</h6>
          <span>{guideData?.action}</span>
        </div>
      </div>
      <div className={`tab-panel ${activeTabIndex === 1 ? 'active' : ''}`} key="tab2">
        {testLog.map((logItem, logIndex) => {
          return <div className={`${logIndex % 2 !== 0 ? 'grey-50' : ''}`}>
            <span className='sdds-detail-04 log-date'>{moment.utc(logItem.logTime).format('HH:mm:ss')}</span> - <span className='sdds-detail-03'>{logItem.logContent}</span>
          </div>
        })}
      </div>
    </div>
  </div>
}

export default FaultCodeActionTabs