import React from 'react'
import { useTranslation } from 'react-i18next';
import { CustomModal, Spinner } from '@cd/sdds-common-components-react';

import FunctionComponent from './FunctionComponent'
import InfoSvg from '../../icons/InfoSvg';
import './AddFunctionModal.scss'

type AddFunctionModalPropsType = {
  onClose: Function,
  show: boolean,
  functionsList: Array<PackageFunctionType>
  onAddFunction: Function,
  isApiLoading: boolean
}
const AddFunctionModal = ({ onClose, show, functionsList, onAddFunction, isApiLoading }: AddFunctionModalPropsType) => {
  const { t } = useTranslation();

  return <>
    <CustomModal id="add-function-modal"
      show={show}
      title="Add function"
      handleClose={() => onClose()}
      actions={(
        <>
          <button type="button" className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn" onClick={() => onClose()} >{t("TS_core:Cancel")}</button>
        </>
      )}
    >
      {!isApiLoading && functionsList && functionsList.length > 0 && <>
        <div class="info-spacer">
          <div className="add-func-modal-info-msg sdds-u-mb3">
            <InfoSvg color="#2B70D3"></InfoSvg>
            <div className="info-msg">
              <div className="sdds-headline-07 assert-param-lbl" >Assert Parameters</div>
              <div className="sdds-detail-02">
              Parameters for which you should enter a value to compare against when the corresponding function is executed. The function will show success or failure based on if the check matched the entered value.
              </div>
            </div>
          </div>
        </div>
        {functionsList.map((funcionObject) => {
          return <FunctionComponent data={funcionObject} onAddToPackageClick={onAddFunction} onDeleteFromPackageClick={() => { }} key={funcionObject?.id} />
        })
        }
      </>}
      {isApiLoading && <div><Spinner /></div>}
    </CustomModal >
  </>;
}

export default AddFunctionModal;