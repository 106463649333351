import React, { useState } from 'react'
import { Dropdown, Spinner } from '@cd/sdds-common-components-react';
import { useTranslation } from 'react-i18next';
import { isValidUrl, isValidGuid } from '../../helpers/urlValidator';
import './FunctionManagementAccordion.scss'
import AddConfirmationModal from './AddConfirmationModal';

type FuctionManagementFormType = {
    onSubmitForm: (e: any) => void,
    onClose: (flag: boolean) => void,
    isLoading: boolean
}
const FuctionManagementForm = ({ onSubmitForm, isLoading, onClose }: FuctionManagementFormType) => {
    const { t } = useTranslation();
    const [baseUri, setBaseUri] = useState('')
    const [functionId, setfunctionId] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [preCondition, setPreCondition] = useState('')
    const [postCondition, setPostCondition] = useState('')
    const [version, setVersion] = useState('1.0')
    const [errorMessage, setErrorMessage] = useState('');
    const [errorfunctionId, setErrorFunctionId] = useState('');
    const [isConfirmModalVisible, setConfirmModalVisible] = useState<Boolean>(false)

    const handleRegister = () => {
        setConfirmModalVisible(false);
        const formData = {
            baseUri: baseUri,
            description: description,
            id: functionId,
            name: name,
            version: version,
            preCondition: preCondition,
            postCondition: postCondition
        }

        if (!isValidUrl(formData.baseUri)) {
            setErrorMessage("You entered an Invalid URL!");
            return
        } else {
            setErrorMessage('');
        }
        if (!isValidGuid(formData.id)) {
            setErrorFunctionId("You entered an Invalid Funcation ID - GUID!");
            return;
        } else {
            setErrorFunctionId('');
        }
        onSubmitForm(formData);
    }

    return (
        <>
            {isLoading && <div className="spinner-container"><Spinner size="lg" /></div>}
            <div className="sdds-body-02 form">
                <div className="inputs_wrapper">
                    <div className="input">
                        <p className="sdds-headline-06 tag"> Base URL *</p>
                        <input
                            type="text"
                            placeholder="Enter URL"
                            value={baseUri}
                            onChange={(e) => setBaseUri(e.target.value)}
                        />
                        {errorMessage && <p className="error">{errorMessage}</p>}
                    </div>
                    <div className="input">
                        <p className="sdds-headline-06  tag">Function ID *</p>
                        <input
                            type="text"
                            value={functionId}
                            onChange={(e) => setfunctionId(e.target.value)}
                            placeholder="Enter Function ID - Globally Unique Identifier(GUID)"
                        />
                        {errorfunctionId && <p className="error" >{errorfunctionId}</p>}
                    </div>
                    <div className="input">
                        <p className="sdds-headline-06  tag" >{t("TS_core:Name")} *</p>
                        <input
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter Function Name"
                        />
                    </div>
                    <div className="input">
                        <p className="sdds-headline-06  tag" >{t('TS_core:Description')} *</p>
                        <input
                            type="text"
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter Function Description"
                        />
                    </div>
                    <div className="input">
                        <p className="sdds-headline-06  tag">Pre condition </p>
                        <input
                            type="text"
                            onChange={(e) => setPreCondition(e.target.value)}
                            placeholder="Enter Pre-conditions if any"
                        />
                    </div>
                    <div className="input">
                        <p className="sdds-headline-06  tag">Post condition </p>
                        <input
                            type="text"
                            onChange={(e) => setPostCondition(e.target.value)}
                            placeholder="Enter Post-conditions if any"
                        />
                    </div>
                    <div className="dropdown">
                        <p className="sdds-headline-06  tag">Select Version *</p>
                        {/* For now, we allow only to create with v1.0, we might support more new versions in the future */}
                        <Dropdown data={['v1.0']} placeholder='' selectedValue="v1.0" setSelectedValue={() => setVersion('1.0')} />
                    </div>
                </div>
                <div className="buttons">
                    <button className='sdds-btn sdds-btn-secondary sdds-btn-sm ' title={'TS_core:Cancel'} onClick={() => onClose(false)} >{t("TS_core:Cancel")}</button>
                    <button className='sdds-btn sdds-btn-primary sdds-btn-sm register-function '
                        title={'TS_core:Submit'}
                        type="submit"
                        onClick={() => setConfirmModalVisible(true)}
                        disabled={!baseUri || !functionId || !name || !description}
                    >
                        {t("TS_core:Submit")}
                    </button>
                </div>
            </div>
            {isConfirmModalVisible && <AddConfirmationModal onClose={() => setConfirmModalVisible(false)} onAction={handleRegister} newFunction={{ baseUri, functionId }} />}
        </>
    );
}
export default FuctionManagementForm