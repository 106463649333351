import React from 'react'
import { TdsSpinner } from '@scania/tegel-react'
import { t } from 'i18next'
import TegelTextField from '../../components/TegelTextField/TegelTextField'
import "./FirstStep.scss"
import PersonnelSearch from './PersonnelSearch'
import { getValidationObjectByParamName } from '../../helpers/cmapsValidationHelper'

type FirstStepProps = {
    storeData: SearchState,
    personnelSearch: any,
    selectingPersonnel(personnel: any): void,
    selectedPersonnel: any
    requirePersonelSearch: boolean
    hasError: boolean
    functionsParams: string[]
    onFunctionParameterChange: (parameterName: string, value: string,isValid:boolean) => void
    isLoadingParameters: boolean
}
const FirstStep = (props: FirstStepProps) => {
    const { storeData, personnelSearch, selectingPersonnel, selectedPersonnel, requirePersonelSearch,
        hasError, functionsParams, onFunctionParameterChange, isLoadingParameters } = props

    return (<>
        {storeData &&
            <div className="st-first-step">
                {requirePersonelSearch && <div>
                    <div className="sdds-u-mt2">
                        {!personnelSearch && !hasError && <TdsSpinner size='sm' />}
                        {personnelSearch && personnelSearch.length > 0 && <>
                            <p className="title">{t("TS_core:PERSONNEL")}</p>
                            <PersonnelSearch hasError={hasError} personnelList={personnelSearch} onPersonSelected={selectingPersonnel} selectedPersonnel={selectedPersonnel} />
                        </>}
                    </div>
                </div>}
                <div className="tds-container-fluid tds-u-p0">
                    {functionsParams && functionsParams.map((paramName: string, index: number) => {
                        const validationObject = getValidationObjectByParamName(paramName)
                        return <div className="tds-row tds-u-mt3 tds-u-p0" key={paramName + index}>
                            <div className="tds-col-xlg-5 tds-col-lg-8 tds-col-md-8 tds-col-sm-10 tds-col-xs-10">
                                <TegelTextField
                                    autofocus={index === 0}
                                    onValueChange={onFunctionParameterChange}
                                    parameterName={paramName}
                                    label={t(`TS_temp:${paramName}`)}
                                    validationPattren={validationObject?.regex}
                                    pattrenValidationMsg={validationObject?.validatoinMessageResourceKey}
                                    helperMessage={validationObject?.helperTextResourceKey}
                                    customValidationFunction={validationObject?.validationFunction}
                                    type={validationObject?.inputType}
                                />
                            </div>
                        </div>
                    })}
                </div>

                <div className="sdds-body-02 no-input-msg">
                    {!requirePersonelSearch && (!functionsParams || functionsParams.length === 0) && !isLoadingParameters && <>
                        {t("TS_core:NoInputsRequired")}
                    </>}
                    {isLoadingParameters && <TdsSpinner size="md" />}
                </div>
            </div>}
    </>
    )
}

export default FirstStep;