import { AxiosPromise } from 'axios';
import { csttFacadeApi } from '../csttFacadeApi';


// https://testapits.cs.scania.com/csttfacade/tsguide/v1/guides/003-01-02-00?culturecode=en-GB
const getGuideByFaultCode = (faultCode: string, cultureCode: string): AxiosPromise<TsGuide> => csttFacadeApi.get(`/csttfacade/tsguide/v1/guides/${faultCode}?culturecode=${cultureCode}`);
const getPackages = (): AxiosPromise<GetPackagesResponseType> => csttFacadeApi.get(`/csttfacade/tsguide/v1/packages`);
const getFunctionsFromPackage = (packageId: string): AxiosPromise<PackageType> => csttFacadeApi.get(`/csttfacade/tsguide/v1/packages/${packageId}`);
const getCheckIdAndParams = (baseUri: string, testFunctionId: string): AxiosPromise<PackageType> => csttFacadeApi.get(baseUri + testFunctionId);
const executeFunction = (requestParams: any): AxiosPromise<String> => csttFacadeApi.post('/csttfacade/tsguide/v1/functions/execute', requestParams);
const getFunctionLogs = (sessionId: string): AxiosPromise<TestLogType[]> => csttFacadeApi.get(`/csttfacade/tsguide/v1/functions/session/${sessionId}`);
const deleteFunctionLogs = (sessionId: string): AxiosPromise<TestLogType> => csttFacadeApi.delete(`/csttfacade/tsguide/v1/functions/session/${sessionId}`);
const getPersonalsList = (id: string): AxiosPromise<TestLogType> => csttFacadeApi.get(`/csttfacade/staff/v1/customer/${id}`);
const getTsFunction = (functionId: string): AxiosPromise<TsFunction> => csttFacadeApi.get('/csttfacade/tsguide/v1/functions/' + functionId)

export { getGuideByFaultCode, getPackages, getFunctionsFromPackage, getCheckIdAndParams, getFunctionLogs, deleteFunctionLogs, getPersonalsList, getTsFunction,
         executeFunction }
