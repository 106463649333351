import React from 'react';
import './SearchBar.scss';
import { useTranslation } from 'react-i18next';

type SearchBarProps = {
    disabled: boolean,
    setSearchedValue: Function,
}

const SearchBar = (props: SearchBarProps) => {
    const { t } = useTranslation();
    const { setSearchedValue, disabled } = props;
    const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchedValue(event.target.value)
    }

    return (
        <div className='searchBar'>
            <input disabled={disabled} id='searchInput' type='text' title={t('TS_core:Search')} placeholder={t('TS_core:Search')} onChange={handleChangeEvent} />
        </div>
    )
}

export default SearchBar;
