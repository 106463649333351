import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { fetchFunctionManagementList, deletingFunctionManagement, addingFunctionManagement } from '../../redux/store/functionManagement'
import FuctionManagementAccordion from '../../components/FunctionManagement';
import FuctionManagementForm from '../../components/FunctionManagement/FunctionManagementForm';
import './FunctionManagement.scss'
import { Spinner, useToast, Toast } from '@cd/sdds-common-components-react';
import type { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';

const FunctionManagement = () => {
    const { toastQueue, addToast } = useToast();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false)
    const dispatch = useAppDispatch()
    const { functionsList, functionsListLoading, isLoading, hasError, errorMessage, isSaveSuccess }: FunctionManagementState = useAppSelector<FunctionManagementState>((state: RootState) => state.functionManagement)

    const createFunction = (e: any) => {
        dispatch(addingFunctionManagement(e))
    }
    const deletingFunction = (i: string) => {
        dispatch(deletingFunctionManagement(i))
    }

    useEffect(() => {
        dispatch(fetchFunctionManagementList())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isSaveSuccess && !isLoading) {
            addToast({ title:  t("TS_core:Success"), text: "Function was added successfully", type: "success" });
        }
        else if (hasError && !isLoading) {
            const toastMessage = errorMessage !== '' ? t(errorMessage) : t("TS_core:UnExpectedErrorTitle");
            addToast({ title: "Error", text: toastMessage, type: "error" });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, hasError, isSaveSuccess, errorMessage])

    return (
        <>
        <div className="section-scrollable">
            {!functionsListLoading && <div className="registerButton">
                <button className='sdds-btn sdds-btn-primary sdds-btn-sm' title="Register new function" onClick={() => setOpen(true)} >Register new function</button>
            </div>}

            {open && <FuctionManagementForm onClose={setOpen} onSubmitForm={createFunction} isLoading={isLoading} />}
            {functionsListLoading ? <Spinner /> :
                Object.keys(functionsList).map((key,) => {
                    return (
                        <FuctionManagementAccordion deletingFunction={deletingFunction} data={functionsList[key]} title={key} key={key} />
                    )

                })
            }
            <Toast toastQueue={toastQueue} />
            </div>
        </>
    );
}
export default FunctionManagement;