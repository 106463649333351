export const SUPPORTED_FUNCTION_PARAMS = [
  'vin',
  'chassisnumber',
  'externalcustomerreference',
  'externalequipmentreference',
  'externalstaffreference',
  'communicationdevice'
];

export const CONNTECTD_MAP_FUNCTION_PARAMS = [
  "mapversion",
  "boundingboxcoordinates"
]

