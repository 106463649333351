import { createSlice } from '@reduxjs/toolkit'
import { getAllFunctions } from '../../adapters/TsGuideManagement';
import { getRoles } from '../../adapters/UserAdapter'

import { getAllPackages, addFunctionToPackage, getPackageById, removeFunctionFromPackage, deletePackage, savePackage, addNewPackage } from '../../adapters/PackageManagement';

const getInitialState = (): PackageManagementState => {
    const faultGuideInitialState: PackageManagementState = {
        packages: [],
        selectedPackage: null,
        isPakageListApiLoading: false,
        isPackageApiLoading: false,
        functionsList: [],
        isFunctionsApiLoading: false,
        isFunctionsListApiLoading: false,
        appRoles: [],
        hasError: false,
        errorMessage: ""
    };
    return faultGuideInitialState;
}

// Slice
const slice = createSlice({
    name: 'packageManagement',
    initialState: getInitialState(),
    reducers: {
        updatePakageList: (state, action) => {
            state.packages = action.payload
        },
        updateFunctionsList: (state, action) => {
            state.functionsList = action.payload
        },
        updatePakageListApiLoading: (state, action) => {
            state.isPakageListApiLoading = action.payload
        },
        updatePakageApiLoading: (state, action) => {
            state.isPackageApiLoading = action.payload
        },
        updateSelectedPackage: (state, action) => {
            state.selectedPackage = action.payload
        },
        updateFunctionsApiLoading: (state, action) => {
            state.isFunctionsApiLoading = action.payload
        },
        updateFunctionsListApiLoading: (state, action) => {
            state.isFunctionsListApiLoading = action.payload
        },
        updateAppRoles: (state, action) => {
            state.appRoles = action.payload
        },
        updateError: (state, action) => {
            state.hasError = (action.payload !== null || action.payload !== "");
            state.errorMessage = action.payload
        }
    },
});
export default slice.reducer

// Actions
const { updatePakageList, updatePakageListApiLoading, updatePakageApiLoading, updateSelectedPackage, updateFunctionsList, updateFunctionsListApiLoading, updateAppRoles, updateError } = slice.actions

export const fetchAppRoles = () => async (dispatch: any) => {
    try {
        const res = await getRoles();
        dispatch(updateAppRoles(res.data));
    } catch (e) {
        return console.error(e);
    }
}

export const fetchPackageList = () => async (dispatch: any) => {

    dispatch(updatePakageListApiLoading(true))
    try {
        const res = await getAllPackages()
        dispatch(updatePakageList(res.data));
        dispatch(updatePakageListApiLoading(false))
    } catch (e) {
        return (console.error(e), dispatch(updatePakageListApiLoading(false)));
    }
}

export const fetchFunctionsList = () => async (dispatch: any) => {
    dispatch(updateFunctionsListApiLoading(true));
    try {
        const res = await getAllFunctions()
        dispatch(updateFunctionsList(res.data));
        dispatch(updateFunctionsListApiLoading(false));
    } catch (e) {
        return (console.error(e), dispatch(updateFunctionsListApiLoading(true)));
    }
}

export const selectPackage = (selectedPackage: PackageType) => async (dispatch: any) => {

    dispatch(updatePakageApiLoading(true));
    try {
        if (selectedPackage.id !== null) {
            const res = await getPackageById(selectedPackage.id)
            dispatch(updateSelectedPackage(res.data));
        } else {
            dispatch(updateSelectedPackage(selectedPackage));
        }
        dispatch(updatePakageApiLoading(false));
    } catch (e) {
        return (console.error(e), dispatch(updatePakageApiLoading(true)));
    }
}

export const addFunction = (functionPayload: AddFunctionPayloadType) => async (dispatch: any) => {

    dispatch(updatePakageApiLoading(true));
    try {
        await addFunctionToPackage(functionPayload)
        const res = await getPackageById(functionPayload.packageId)
        dispatch(updateSelectedPackage(res.data));
        dispatch(updatePakageApiLoading(false));
    } catch (e) {
        return (console.error(e), dispatch(updatePakageApiLoading(true)));
    }
}

export const removeFromPackage = (packageId: string, functionId: string) => async (dispatch: any) => {

    try {
        await removeFunctionFromPackage(packageId, functionId)
        dispatch(updatePakageApiLoading(true));
        const res = await getPackageById(packageId)
        dispatch(updateSelectedPackage(res.data));
        dispatch(updatePakageApiLoading(false));
    } catch (e) {
        return (console.error(e), dispatch(updatePakageApiLoading(true)));
    }
}

export const sendDeletePackage = (packageId: string) => async (dispatch: any) => {

    dispatch(updatePakageApiLoading(true));
    try {
        await deletePackage(packageId)
        dispatch(fetchPackageList());
        dispatch(updateSelectedPackage(null));
        dispatch(updatePakageApiLoading(false));
    } catch (e) {
        console.error(e)
        dispatch(updatePakageApiLoading(true))
    }
}
export const sendSavePackage = (packagePayload: PackageUpdatePayloadType) => async (dispatch: any) => {
    dispatch(updateError(""))

    dispatch(updatePakageApiLoading(true));
    try {
        if (packagePayload.id == null) {
            await addNewPackage(packagePayload)
        } else {
            await savePackage(packagePayload)
        }
        const res = await getPackageById(packagePayload.id)
        dispatch(updateSelectedPackage(res.data));
        dispatch(fetchPackageList());
        dispatch(updatePakageApiLoading(false));
    } catch (e:any) {
        dispatch(updateError(e.message))
        dispatch(updatePakageApiLoading(false));
        console.error(e);
    }
}