import React, { useState, useEffect } from 'react';
import { CustomModal } from '@cd/sdds-common-components-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import './ServiceTestDetailViewModel.scss'
import FaultCodeActionTabs from '../STTestResult/FaultCodeActionTabs';
import { getGuideByFaultCode } from '../../adapters/TsGuide'
import DoCSFunctionButton from '../DoCSFunctionButton';


type DetailModelViewProps = {
  docsFunction: PackageFunctionType
  FunctionExecution?: string
  show: boolean
  onClose?: () => void
}

const ServiceTestDetailViewModel = ({ show, onClose, docsFunction }: DetailModelViewProps) => {
  const { i18n, t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(1);
  const [faultAction, setFaultAction] = useState(false);
  const [guide, setGuide] = useState<TsGuide>(null);
  const [isUnkownFaultCode, setUnkownFaultCode] = useState<Boolean>(false);


  const onSelectTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex)
  }

  useEffect(() => {
    if (docsFunction.faultCode) {
      setActiveTabIndex(0);
      getGuideByFaultCode(docsFunction.faultCode, i18n.language).then((guideResponse) => {
        setGuide(guideResponse.data);
      }).catch((error: Error) => {
        setUnkownFaultCode(true);
      });
    }
  }, [docsFunction.faultCode, i18n.language]);

  return <CustomModal
    id="detailedViewModal"
    show={show}
    title={t(`${docsFunction.name.replace(/([A-Z])/g, ' $1').trim()} Test`)}
    handleClose={onClose}
    actions={(
      <button className="sdds-btn sdds-btn-sm sdds-btn-secondary" onClick={() => onClose} >{t("TS_core:Cancel")}</button>
    )}>
    <div slot="sdds-modal-body" className='detailedViewModal' key="service-test-modal-body">
      <sdds-inline-tabs-fullbleed>
        {docsFunction.faultCode && <div className={`sdds-inline-tabs-fullbleed--tab ${0 === activeTabIndex ? "sdds-navigation-tabs--tab__active" : ""}`} onClick={() => { onSelectTab(0) }} onKeyDown={() => { onSelectTab(0) }} role="button" key={`tab0${0}`}>{t("TS_core:FaultCodeDetails")}</div>}
        <div className={`sdds-inline-tabs-fullbleed--tab ${1 === activeTabIndex ? "sdds-navigation-tabs--tab__active" : ""}`} onClick={() => { onSelectTab(1) }} onKeyDown={() => { onSelectTab(1) }} role="button" key={`tab0${1}`}>{t("TS_core:TestLog")}</div>
      </sdds-inline-tabs-fullbleed>
      {activeTabIndex === 0 && docsFunction.faultCode && <div className='main fault-code-box' key="faultCode">
        <div className="sdds-detail-04 fault-code-txt">{t("TS_core:FaultCodeLabel") + ': ' + docsFunction.faultCode}</div>
        {isUnkownFaultCode && <div className="unknown-fault-code-box">
          <img src='assets\icons\Warning.svg' alt='' /><div>{t("TS_core:UnrecognizedFaultCode")}</div>
        </div>}
        {!isUnkownFaultCode && <>
          <div className='box' >
            <h6 className='sdds-headline-06'>{t('TS_core:Description')} :</h6>
            <span className='sdds-detail-03'>{guide?.description}</span>
          </div>
          <div className='box' >
            <h6>{t("TS_core:PossibleCauses")} :</h6>
            <span className='sdds-detail-03'>{guide?.cause}</span>
          </div>
          <div className='box'>
            <h6>{t("TS_core:Action")} :</h6>
            <span className='sdds-detail-03'>{guide?.action}</span>
            {guide?.functions && guide?.functions.length > 0 && guide?.functions.map((functionId: string) => {
              return <DoCSFunctionButton onClick={() => setFaultAction(true)} functionId={functionId} />
            })}
            <span></span>
          </div>
        </>
        }
        {faultAction && <FaultCodeActionTabs faultCode={docsFunction.faultCode} testLog={docsFunction.logs} />}
      </div>}
      {activeTabIndex === 1 && <div className='main logs-box' key="testLogs">
        <div className="">
          {docsFunction.logs && docsFunction.logs.map((logItem: any, logIndex: number) => {
            return <div className={`${logIndex % 2 !== 0 ? 'grey-50' : ''}`} key={`log0${logIndex}`}>
              <span className='sdds-detail-04 log-date'>{moment.utc(logItem?.logTime).format('HH:mm:ss')}</span> - <span className='sdds-detail-03'>{logItem?.logContent}</span>
            </div>
          })}
        </div>
      </div>}
    </div>

  </CustomModal >;
}

export default ServiceTestDetailViewModel