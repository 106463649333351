import React, { useEffect, useState } from 'react';
import STTestResult from "../../components/STTestResult";
import './STtestStep.css';
import { useToast, Toast, Message, Spinner } from '@cd/sdds-common-components-react';
import { useAppDispatch } from './../../redux/store/hooks'
import { updateRuningStatus, startExecution } from "../../redux/store/tsGuide";
import { getEqParams } from '../../helpers/docsFunctionHelper';

import { t } from 'i18next';
import { CONNTECTD_MAP_FUNCTION_PARAMS } from '../../helpers/constants';

type STTestStepProps = {
    testStep: number
    packageId: string
    eqParams: any
    testList: any
    apiError: boolean
    apiNoData: boolean,
    packageFunctionsList: PackageFunctionType[]
    autoRun: boolean
};

const STTestStep = (props: STTestStepProps) => {
    const { testStep, eqParams, testList, apiNoData, apiError, packageFunctionsList, autoRun } = props;
    const dispatch = useAppDispatch()
    const { toastQueue, addToast } = useToast();
    const [paramValueMissing, setParamValueMissing] = useState<boolean>(false);
    const [missingValues, setMissingValues] = useState<Array<string>>([]);

    useEffect(() => {
        if (apiError) {
            addToast({ title: "Alert!", text: t("TS_core:UnExpectedErrorMessage"), type: "error" })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiError])

    useEffect(() => {
        if (apiNoData) {
            addToast({ title: "Warning!", text: t("TS_core:NoFuncationsSetupForTheTroubleshooting"), type: "warning" })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiNoData])


    const allRequiredEqParamsAvailable = (existingEqParams: RequiredEquipmentParametersType, expectedEqParams: string[] | undefined) => {
        let allParamsAvailable = true;
        let nonAvailableParams: Array<string> = [];
        Object.keys(eqParams).forEach((key) => {
            if (!eqParams[key] && expectedEqParams && expectedEqParams.includes(key) && !CONNTECTD_MAP_FUNCTION_PARAMS.includes(key)) {
                allParamsAvailable = false;
                nonAvailableParams.push(key);
            }
        })
        setMissingValues(nonAvailableParams);
        return allParamsAvailable;
    }

    const dispatchingCheckRunningStatus = (functionId: string, status: TestResultTypes) => {
        dispatch(updateRuningStatus(functionId, status))
    }
    const onStartExecution = (docsFunction: PackageFunctionType) => {
        //check for any missing params first
        setParamValueMissing(!allRequiredEqParamsAvailable(eqParams, docsFunction.contextSpec?.functionParameters))
        if (missingValues.length === 0) {
            const functionParams = getEqParams(docsFunction, eqParams)
            dispatch(startExecution(docsFunction.id, functionParams))
        }
    }
    return <>
        <div className="TestContainer">
            {paramValueMissing &&
                <Message title={t('TS_core:TestCanNotRunMissingParameter')} message={t("TS_core:EqParamsMissingError") + ' ( ' + missingValues.join(",") + ' )'} type="error" minimal={false} />
            }
            {!paramValueMissing && packageFunctionsList && packageFunctionsList.length > 0 ? <>
            
                {testList && testList.length > 0 && testList.map((test: PackageFunctionType, index: number) => {
                    return <STTestResult docsFunction={test} testStep={testStep}
                        onUpdateRunningStatus={dispatchingCheckRunningStatus}
                        onStartExecution={onStartExecution} key={index} autoRun={autoRun} />
                })}</> : <Spinner/>
            }
        </div>
        <Toast toastQueue={toastQueue} />
    </>;
}
export default STTestStep;