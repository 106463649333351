
import _ from "lodash";
import moment from "moment";

const generateExportData = (data: ServiceTestRecord[], t: any): any[] => {
  const checks = _.filter(data, function (c) {
    return c.logs;
  }) as ServiceTestRecord[];
  return _.map(_.clone(checks), function (c) {
    var log = '';
    _.forEach(c.logs, function (logOutput, i) {
      log += moment.utc(logOutput.logTime).format('HH:mm:ss') + ' - ' + logOutput.logContent;
      log += i === c.logs.length - 1 ? '' : '\r\n';
    });
    return {
      name: t(c.name, c.name),
      log: log,
      description: t(c.name + 'Description', c.description),
      startTime: moment.utc(c.logs[0].logTime).format('YYYYMMDD HH:mm:ss'),
      endTime: moment.utc(_.last(c.logs).logTime).format('YYYYMMDD HH:mm:ss'),
      faultCode: _.isNull(_.last(c.logs).faultCode) ? '' : _.last(c.logs).faultCode
    };
  });
}
const getExcelConfig = (t: any) => {
  return {
    headers: true,
    column: {
      style: 'font-size:16px'
    },
    columns: [
      {
        columnid: 'faultCode',
        title: t('TS_core:Result'),
        cell: {
          value: function (val: string) {
            return val === '' ? t('TS_core:Success') : t('TS_core:Failed');
          }
        }
      },
      { columnid: 'name', title: t("TS_core:Name") },
      { columnid: 'description', title: t('TS_core:Description') },
      { columnid: 'startTime', title: t('TS_core:StartTime') },
      { columnid: 'endTime', title: t('TS_core:EndTime') },
      { columnid: 'log', title: t('TS_core:Log') },
      { columnid: 'faultCode', title: t('TS_core:FaultCode') }
    ]
  };
}

export {
  generateExportData,
  getExcelConfig
}