import React, { useState } from 'react'
import ArrowDownSvg from '../../icons/ArrowDown';
import './FunctionManagementAccordion.scss'
import FuctionManagementDetails from './FunctionManagementDetails';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type FuctionManagementAccordionType = {
    data: any,
    title: string,
    deletingFunction(i: string): void
}
const FuctionManagementAccordion = (props: FuctionManagementAccordionType) => {
    const { data, title, deletingFunction } = props
    const [selectedId, setSelectedId] = useState('')
    const [open, setOpen] = useState(false)
    const { t } = useTranslation();

    return (
        <div className="accordionWrapper">
            <div className="header">{title}</div>
            {data?.map((item: any) => {
                return (
                    <div className="bodywrapper" key={item.id}>
                        <div className="info">
                            <div className="left">
                                <p>{item.name}</p>
                                {/* eslint-disable-next-line no-useless-computed-key*/}
                                <div className={clsx('tag', { ['tag_yellow']: item.version === '0.1' })}><span>v{item.version}</span></div>
                                {!item.contextSpec && <p className="unavailable">{t("TS_core:Unavailable")}</p>}
                            </div>
                            <div className="right">
                                <div className="function_id"> Function ID</div>
                                <p>{item.id}</p>
                                {/* eslint-disable-next-line no-useless-computed-key*/}
                                <div className={clsx('dropdown', { ['dropdown_active']: open && selectedId === item.id })}
                                    role="button"
                                    onClick={() => { setSelectedId(item.id); setOpen(!open) }}
                                    onKeyDown={() => { setSelectedId(item.id); setOpen(!open) }}
                                >
                                    <ArrowDownSvg />
                                </div>
                            </div>
                        </div>
                        {(open && selectedId === item.id) &&
                            <div className="detailsWrapper">
                                <FuctionManagementDetails deleting={deletingFunction} detailsData={item} />
                            </div>
                        }
                    </div>
                )
            })
            }
        </div>
    );
}
export default FuctionManagementAccordion;