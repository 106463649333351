import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const utcFormatFromString = (datetime: string) : string => { 
  return datetime ? dayjs.utc(datetime).format('YYYY-MM-DD HH:mm:ss') : '-';
};

const utcFormatDayFromString = (datetime: string) : string => { 
  return datetime ? dayjs.utc(datetime).format('YYYY-MM-DD') : '-';
};

const utcFormatFromDate = (datetime: Date) : string => dayjs(datetime).utc().format('YYYY-MM-DD HH:mm:ss');

const utcTimeFromString = (datetime: string) : string => {
  return datetime ? dayjs.utc(datetime).format('HH:mm:ss') : '-';
};

export {
  utcFormatFromString,
  utcFormatFromDate,
  utcTimeFromString,
  utcFormatDayFromString
};
