import React, { MouseEventHandler } from 'react'
type IconColor = {
    color: string,
    onMouseOver?: MouseEventHandler
}

const InfoSvg = (props: IconColor) => {
    const { color, onMouseOver } = props;
    return (
        <svg onMouseOver={onMouseOver} className='info-svg-icon' width="32" height="32" viewBox="0 0 32 32" fill="currentColor" color={color} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" >
            <path fillRule="evenodd" clipRule="evenodd" d="M16.013 4.004c-6.642 0-12.026 5.384-12.026 12.025 0 6.642 5.384 12.026 12.026 12.026 6.641 0 12.025-5.384 12.025-12.026 0-6.641-5.384-12.025-12.025-12.025ZM1.987 16.029c0-7.746 6.28-14.025 14.026-14.025 7.746 0 14.025 6.28 14.025 14.025 0 7.746-6.28 14.026-14.025 14.026-7.746 0-14.026-6.28-14.026-14.026Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.025 14.126a1 1 0 0 1 1 1v5.616a1 1 0 0 1-2 0v-5.616a1 1 0 0 1 1-1ZM16.025 10.317a1 1 0 0 1 1 1v.217a1 1 0 1 1-2 0v-.217a1 1 0 0 1 1-1Z" fill="currentColor" />
        </svg>
    )
}
export default InfoSvg