import { createSlice } from '@reduxjs/toolkit'
import { getAllGuides, saveGuide, updateGuide, deleteGuide, getAllFunctions } from '../../adapters/TsGuideManagement';

const getInitialState = (faultGuides: Array<TsGuideDto> | null): FaultGuideManagementState => {
    const faultGuideInitialState: FaultGuideManagementState = {
        searchedGuide: '',
        selectedGuideDto: {
            "status": 'Draft',
            "action": "",
            "cause": "",
            "description": "",
            "functions": [],
            "id": ""
        },
        userAction: 'hide',
        allGuides: faultGuides || [],
        faultGuidesApiLoading: false,
        tsFunctions: [],
        tsFunctionsApiLoading: false,
        CUDApiResult: 'Default',
        CUDApisLoading: false, // 'Create / Update / Delete' Fault guide APIs loading state,
    };
    return faultGuideInitialState;
}

// Slice
const slice = createSlice({
    name: 'faultGuideManagement',
    initialState: getInitialState(null),
    reducers: {
        updateSearchedGuide: (state, action) => {
            state.searchedGuide = action.payload
        },
        updateSelectedGuideDto: (state, action) => {
            state.selectedGuideDto = action.payload
        },
        updateSelectedUserAction: (state, action) => {
            state.userAction = action.payload
        },
        updateFaultGuides: (state, action) => {
            state.allGuides = action.payload
        },
        updateFaultGuidesApiLoading: (state, action) => {
            state.faultGuidesApiLoading = action.payload
        },
        updateAllTsFunctions: (state, action) => {
            state.tsFunctions = action.payload
        },
        updateTsFunctionsApiLoading: (state, action) => {
            state.tsFunctionsApiLoading = action.payload
        },
        updateCUDApisLoading: (state, action) => {
            state.CUDApisLoading = action.payload
        },
        updateCUDApisResult: (state, action) => {
            state.CUDApiResult = action.payload
        },
    },
});
export default slice.reducer

// Actions
const { updateSearchedGuide, updateSelectedGuideDto, updateSelectedUserAction, updateFaultGuides, updateFaultGuidesApiLoading,
     updateCUDApisResult, updateAllTsFunctions, updateTsFunctionsApiLoading, updateCUDApisLoading } = slice.actions

export const _updateSearchedGuide = (value: string) => async (dispatch: any) => {
    try {
        dispatch(updateSearchedGuide(value));
    } catch (e) {
        return (console.error(e));
    }
}

export const _updateSelectedGuideDto = (data: TsGuideDto) => async (dispatch: any) => {
    try {
        dispatch(updateSelectedGuideDto(data));
    } catch (e) {
        return console.error(e);
    }
}

export const _updateSelectedUserAction = (data: FormStateType) => async (dispatch: any) => {
    try {
        dispatch(updateSelectedUserAction(data));
    } catch (e) {
        return console.error(e);
    }
}

export const _updateAllGuides = (cultureCode:string) => async (dispatch: any) => {
    
    dispatch(updateFaultGuidesApiLoading(true))
    try {
        const res = await getAllGuides(cultureCode)
        dispatch(updateFaultGuides(res.data));
        dispatch(updateFaultGuidesApiLoading(false))
    } catch (e) {
        return (console.error(e), dispatch(updateFaultGuidesApiLoading(false)));
    }
}
export const _updateAllTsFunctions = () => async (dispatch: any) => {
    dispatch(updateTsFunctionsApiLoading(true))
    try {
        const res = await getAllFunctions()
        dispatch(updateAllTsFunctions(res.data));
        dispatch(updateTsFunctionsApiLoading(false))
    } catch (e) {
        return (console.error(e), dispatch(updateTsFunctionsApiLoading(false)));
    }
}


export const _deleteGuide = (id: string, cultureCode:string) => async (dispatch: any) => {
    dispatch(updateCUDApisLoading(true))
    try {
        await deleteGuide(id)
        dispatch(updateCUDApisResult('succeeded'));
        dispatch(updateCUDApisLoading(false))
        dispatch(_updateAllGuides(cultureCode));

    } catch (e) {
        return (console.error(e), dispatch(updateCUDApisLoading(false)), dispatch(updateCUDApisResult('failed')));
    }
}

export const _updateGuide = (guide: TsGuideDto, cultureCode:string) => async (dispatch: any) => {
    dispatch(updateCUDApisLoading(true))
    try {
        await updateGuide(guide)
        dispatch(updateCUDApisResult('succeeded'));
        dispatch(updateCUDApisLoading(false))
        dispatch(_updateAllGuides(cultureCode));

    } catch (e) {
        return (console.error(e), dispatch(updateCUDApisLoading(false)), dispatch(updateCUDApisResult('failed')));
    }
}

export const _saveGuide = (guide: TsGuideDto, cultureCode:string) => async (dispatch: any) => {
    dispatch(updateCUDApisLoading(true))
    try {
        await saveGuide(guide)
        dispatch(updateCUDApisResult('succeeded'));
        dispatch(updateCUDApisLoading(false))
        dispatch(_updateAllGuides(cultureCode));

    } catch (e) {
        return (console.error(e), dispatch(updateCUDApisLoading(false)), dispatch(updateCUDApisResult('failed')));
    }
}

export const _updateCUDApiResult = updateCUDApisResult;
