import React, { useState, useEffect } from 'react'
import styles from './STTestResult.module.css';
import WarningSvg from '../../icons/Warning';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ServiceTestDetailViewModel from '../ServiceTestDetailViewModel';
import { getGuideByFaultCode } from '../../adapters/TsGuide';
import DoCSFunctionButton from '../DoCSFunctionButton';
import FaultCodeActionTabs from './FaultCodeActionTabs';
import { TdsSpinner } from '@scania/tegel-react';


type TestResultPropType = {
    docsFunction: PackageFunctionType
    testStep: number
    autoRun: boolean
    onUpdateRunningStatus: Function
    onStartExecution: Function
};

const STTestResult = (props: TestResultPropType) => {
    const { docsFunction, autoRun, onUpdateRunningStatus, onStartExecution } = props;
    const tStatus = docsFunction.runningStatus;
    const { i18n, t } = useTranslation();
    const [disableDetails, setDisableDetails] = useState<boolean>(true);
    const [disableRun, setDisableRun] = useState<boolean>(false);
    const [rerun, setRerun] = useState<boolean>(false);
    const [detailsViewVisible, setDetailsViewVisible] = useState<boolean>(false);
    const [isUnkownFaultCode, setUnkownFaultCode] = useState<Boolean>(false);
    const [guide, setGuide] = useState<TsGuide>(null);
    const [faultAction, setFaultAction] = useState(false);
    const [viewLog, setViewLog] = useState(false);
    const [viewDetails, setViewDetails] = useState(false);


    function closeDetailsView() {
        setDetailsViewVisible(false);
    }
    const startExecution = (isRerun: boolean) => {
        setViewDetails(false)
        onStartExecution(docsFunction);
        isRerun !== rerun && setRerun(isRerun)
    }

    const getWarningText = (fnName: string) => {
        let allText: STWarningTextType = {};
        switch (fnName) {
            case 'SetTimer':
                allText.warning = t("TS_core:SetTimerWarning");
                allText.info = t("TS_core:SetTimerInfo");
                allText.guideline1 = t("TS_core:SetTimerGuideline1");
                allText.guideline2 = t("TS_core:SetTimerGuideline2");
                break;

            case 'TurnOnHeater':
                allText.warning = t("TS_core:TurnOnHeaterWarning");
                allText.info = t("TS_core:TurnOnHeaterInfo");
                break;

            case 'TurnOffHeater':
                allText.warning = t("TS_core:TurnOffHeaterWarning");
                allText.info = t("TS_core:TurnOffHeaterinfo");
                break;

            default: ;
        }
        return allText;
    }

    useEffect(() => {
      if (autoRun) {
            if (!docsFunction.logs || docsFunction.logs.length === 0) {
                onUpdateRunningStatus(docsFunction.id, 'Running');
                startExecution(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        switch (docsFunction.runningStatus) {
            case 'Passed':
            case 'Failed':
            case 'Warning':
                setDisableDetails(false);
                setDisableRun(false);
                break;
            case 'Disabled':
            case 'Running':
                setDisableDetails(true);
                setDisableRun(true);
                break;
            case 'Default':
                setDisableDetails(true);
                setDisableRun(false);
                break;
        }
    }, [docsFunction.runningStatus])

    useEffect(() => {
        if (docsFunction.faultCode) {
            getGuideByFaultCode(docsFunction.faultCode, i18n.language).then((guideResponse) => {
                setGuide(guideResponse.data);
            }).catch((error: Error) => {
                setUnkownFaultCode(true);
            });
        }
    }, [docsFunction.faultCode, i18n.language]);

    return (
        <div className={`${styles.outerContainer} ${tStatus}`}>
            <div className={styles.textContainer}>
                <div className={styles.topRow}>
                    <div className={styles.col1}>
                        <div className={`test_status_spinner ${styles.testStatusSection}`}>
                           {/* to be removed if this block of code is duplication of lines 136 to 138  
                           {(tStatus === 'Default' || tStatus === 'Disabled') &&
                                <span className={`sdds-headline-05 ${styles.pkgTestName}`}>
                                    {t(docsFunction.name) === docsFunction.name ? docsFunction.name.replace(/([A-Z])/g, ' $1').trim() : t(docsFunction.name)}
                                </span>} */}                                                                                
                            {(tStatus === 'Running') ? <> <div className={`sdds-headline-05 ${styles.whenLoading}`}>
                                <div className="sdds-u-mr1"><TdsSpinner size='sm' /></div>
                                <span className={styles.pkgTestName}>
                                    {t(docsFunction.name) === docsFunction.name ? docsFunction.name.replace(/([A-Z])/g, ' $1').trim() : t(docsFunction.name)}
                                </span>
                            </div>{docsFunction.description &&
                                <div className={`sdds-body-02 ${styles.testDescription}`}>
                                    {/* When adding new translations for descriptions, the translation keys should always be '[FunctionName]Description' */} 
                                    {t(docsFunction.name + 'Description') === (docsFunction.name + 'Description') ? docsFunction.description : t(docsFunction.name + 'Description')}
                                </div>
                                }</> :
                                <>
                                    <div className={`${styles.status} ${tStatus}`}>
                                        {(tStatus === 'Default' || tStatus === 'Disabled') ? '' : <><img className={styles.statusIcon} src={'../../../../../assets/icons/' + tStatus + '.svg'} alt=" " height="20" width="20" /><div className={`sdds-headline-05 ${styles.testName}`}>
                                            <span className={styles.pkgTestName}>
                                                {t(docsFunction.name) === docsFunction.name ? docsFunction.name.replace(/([A-Z])/g, ' $1').trim() : t(docsFunction.name)}
                                            </span>
                                        </div>{((tStatus === 'Failed' || tStatus === 'Warning') ? t("TS_core:FaultCodeLabel") + docsFunction.faultCode : '')}
                                        </>
                                        }
                                    </div>
                                    {docsFunction.description && <>
                                        <div className={`sdds-body-02 ${styles.testDescription}`}>
                                            {t(docsFunction.name + 'Description') === (docsFunction.name + 'Description') ? docsFunction.description : t(docsFunction.name + 'Description')}
                                        </div>
                                        {(docsFunction.name === 'SetTimer' || docsFunction.name === 'TurnOnHeater' || docsFunction.name === 'TurnOffHeater') &&
                                            <div className={styles.warningBox}>
                                                <div className={styles.warningIconContainer}><WarningSvg color="var(--sdds-warning)"></WarningSvg></div>
                                                <div className={styles.warningText}>
                                                    <div className={styles.setTimerWarning}> {getWarningText(docsFunction.name)?.warning}</div>
                                                    <div className="sdds-u-mt1">{getWarningText(docsFunction.name)?.info}</div>
                                                    <ul>
                                                        {getWarningText(docsFunction.name)?.guideline1 && <li>{getWarningText(docsFunction.name)?.guideline1}</li>}
                                                        {getWarningText(docsFunction.name)?.guideline2 && <li>{getWarningText(docsFunction.name)?.guideline2}</li>}
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    </>}
                                </>
                            }
                        </div>

                    </div>
                    <div className={styles.actionBtnContainer}>
                        {/* disabled & enabled based on state , Run Again/ Run based on step */}
                        {(tStatus === 'Failed' || tStatus === 'Warning' || tStatus === 'Disabled') && <button type="button" className={`sdds-btn sdds-btn-primary sdds-btn-sm ${styles.viewDetailsBtn}`} disabled={disableDetails} onClick={() => { setViewDetails(!viewDetails) }}>{viewDetails ? t("TS_core:HideDetails") : t("TS_core:ViewDetails")}</button>}
                        <button type="button" className={`sdds-btn sdds-btn-secondary sdds-btn-sm ${styles.actionBtn}`} disabled={disableRun} onClick={() => startExecution(true)}>{tStatus === 'Default' ? t('TS_core:Run') : t("TS_core:RunAgain")}</button>
                    </div>
                </div>
                {(tStatus !== 'Default' && tStatus !== 'Running') && docsFunction.logs && <div className={styles.logSection}>
                    <div className={`sdds-body-02 ${styles.logContainer}`}>
                        <div className={styles.logsBox} key="testLogs">
                            {viewLog && docsFunction.logs && docsFunction.logs.map((logItem: any, logIndex: number) => {
                                return <div className={`${styles.logRow} ${logIndex % 2 !== 0 ? styles.grey : ''}`} key={`log0${logIndex}`}>
                                    <span className='log-date'>{moment.utc(logItem?.logTime).format('HH:mm:ss')}</span> - <span>{logItem?.logContent}</span>
                                </div>
                            })}
                            {!viewLog && docsFunction.logs && <>
                                <span className='log-date'>{moment.utc(docsFunction.logs[docsFunction.logs.length - 1]?.logTime).format('HH:mm:ss')}</span> - <span>{docsFunction.logs[docsFunction.logs.length - 1]?.logContent}</span>
                            </>}

                        </div>
                    </div>
                    <button type="button" className={`sdds-btn sdds-btn-secondary sdds-btn-sm ${styles.viewLogBtn}`} disabled={disableDetails} onClick={() => { setViewLog(!viewLog) }}>{viewLog ? t('-') : t('+')}</button>
                </div>}


                {viewDetails && docsFunction.faultCode && <div className={styles.faultDetailsSection}>
                    <div className={styles.faultCodeBox} key="faultCode">
                        <div className={`sdds-headline-05 sdds-u-mb1 ${styles.faultcode}`}>Fault Code : {docsFunction.faultCode}</div>
                        {isUnkownFaultCode && <div className={`${styles.unknownFaultCodeBox} sdds-body-02`}>
                            <img src='assets\icons\Warning.svg' alt='' /><div>{t("TS_core:UnrecognizedFaultCode")}</div>
                        </div>}
                        {!isUnkownFaultCode && <>
                            <div className={styles.box} >
                                <div className='sdds-headline-06 sdds-u-mt2'>{t('TS_core:Description')} :</div>
                                <div className='sdds-body-02 sdds-u-mt1'>{guide?.description}</div>
                            </div>
                            <div className={styles.box} >
                                <div className='sdds-headline-06 sdds-u-mt2'>{t("TS_core:PossibleCauses")} :</div>
                                <div className='sdds-body-02 sdds-u-mt1'>{guide?.cause}</div>
                            </div>
                            <div className={styles.box}>
                                <div className='sdds-headline-06 sdds-u-mt2'>{t("TS_core:Action")} :</div>
                                <div className='sdds-body-02 sdds-u-mt1'>{guide?.action}</div>
                                {guide?.functions && guide?.functions.length > 0 && guide?.functions.map((functionId: string) => {
                                    return <DoCSFunctionButton onClick={() => setFaultAction(true)} functionId={functionId} />
                                })}
                                <span></span>
                            </div>
                        </>
                        }
                        {faultAction && <FaultCodeActionTabs faultCode={docsFunction.faultCode} testLog={docsFunction.logs} />}
                    </div>
                </div>}

            </div>

            <ServiceTestDetailViewModel show={detailsViewVisible} onClose={closeDetailsView} docsFunction={docsFunction} />
        </div>
    );
};

export default STTestResult;