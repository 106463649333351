import React, { ReactNode } from "react";
import alasql from 'alasql';
import { generateExportData, getExcelConfig } from './excelExportService'
import moment from "moment";
import { useTranslation } from "react-i18next";
import ExportIcon from "../../icons/ExportIcon";

type ExcelExportProps = {
  packageName: string,
  data: any[]
  children?: ReactNode
  vin: string
  disabled: boolean
}

const ServiceTestsExcelExport = ({ packageName, data, children, vin, disabled }: ExcelExportProps) => {

  const { t } = useTranslation();
  const exportToXL = () => {
    const config = getExcelConfig(t);
    const preparedData = generateExportData(data, t)
    var formattedPackageName = packageName.replace(' ', '_');
    const timestamp = moment.utc().format('YYYYMMDDHHmm')
    const filename = [formattedPackageName, vin, timestamp,].join('_') + '.xls';
    return alasql.promise('SELECT * INTO XLS("' + filename + '", ?) FROM ?', [config, preparedData]);
  }
  return <>
    <button className="sdds-btn sdds-btn-primary sdds-btn-sm" onClick={() => exportToXL()} disabled={disabled}>
      <span>{children}</span> <span className='sdds-btn-icon export-btn'><ExportIcon /></span>
    </button>
  </>;
}

export default ServiceTestsExcelExport;
