import React, { useState, useEffect, createRef } from 'react'
import { useTranslation } from 'react-i18next';
import AddFunctionModal from './AddFunctionModal';
import FunctionComponent from './FunctionComponent';
import { filter, find, isUndefined } from 'lodash'
import TabsComponent from './TabsComponent';
import Constants from "../../constants";
import './PackageForm.scss'

type PackageFormPropType = {
  selectedPackage: PackageType,
  allFunctions: Array<PackageFunctionType>,
  onAddFunction: Function,
  onRemoveFunction: Function,
  onDeletePackage: Function,
  onSavePackage: Function,
  onLoadFunctionsList: Function
  isFunctionsListApiLoading: boolean
  appRoles: Array<string>
}
const PackageForm = ({ selectedPackage, allFunctions, onAddFunction, onRemoveFunction, onLoadFunctionsList, isFunctionsListApiLoading, appRoles, onDeletePackage, onSavePackage }: PackageFormPropType) => {
  const { t } = useTranslation();
  const pkgNameRef = createRef<HTMLInputElement>();
  const [packageName, setPackageName] = useState<string>("");
  const [pkgNameError, setPkgNameError] = useState<string>("");
  const [packageRoles, setPackageRoles] = useState<Array<string>>([]);
  const [packageType, setPackageType] = useState<string>("Equipment");
  const [showAddFunctionModal, setShowAddFunctionModal] = useState<boolean>(false);
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
  const [hasPackageName, setHasPackageName] = useState(false);
  const [step2Functions, setStep2Functions] = useState<Array<PackageFunctionType>>([]);
  const [step3Functions, setStep3Functions] = useState<Array<PackageFunctionType>>([]);
  const [invalidFunctions, setInvalidFunctions] = useState<Array<PackageFunctionType>>([]);

  const availableFunctions = filter(allFunctions, function (func) {
    return isUndefined(find(selectedPackage.functions, ['id', func.id]));
  });

  const onShowAddFunctionModal = () => {
    onLoadFunctionsList();
    setShowAddFunctionModal(true)
  }

  useEffect(() => {
    setShowAddFunctionModal(false);
    setPackageName(selectedPackage.name);
    setHasPackageName(true)
    setPackageRoles(selectedPackage.roles || [])
    setPackageType(selectedPackage.type)
    setIsDataChanged(false)

    if (selectedPackage && selectedPackage.functions) {
      const invalid = filter(selectedPackage.functions, { contextSpec: null });
      const validFunctions: Array<PackageFunctionType> = filter(selectedPackage.functions, (f) => { return f.contextSpec !== null });

      const step2 = filter(validFunctions, { step: 2 });
      const step3 = filter(validFunctions, { step: 3 });
      setStep2Functions(step2 as Array<PackageFunctionType>);
      setStep3Functions(step3 as Array<PackageFunctionType>);
      setInvalidFunctions(invalid as Array<PackageFunctionType>);
    }
  }, [selectedPackage])

  const onPackageNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var packageName = e.currentTarget.value.trim();
    if (packageName.length > 0)
      setHasPackageName(true);
    else
      setHasPackageName(false);
               
    setPackageName(e.currentTarget.value);
    if (Constants.PackageNameRegex.test(e.currentTarget.value)) {
      setPkgNameError("")
    } else {
      setPkgNameError("error")
    }
  }

  const onRoleClick = (role: string) => {
    const roleIndex = packageRoles.indexOf(role);
    if (roleIndex > -1) {
      const remainingRoles: Array<string> = packageRoles.filter((packageRole) => {
        return packageRole !== role;
      })

      setPackageRoles(remainingRoles)
    } else {
      setPackageRoles([...packageRoles, role]);
    }
    setIsDataChanged(true)
  }
  const onPackageTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPackageType(e.currentTarget.value)
    setIsDataChanged(true)
  }
  const onSaveClick = () => {
    if (pkgNameError !== "") {
      pkgNameRef.current?.focus();
    } else {
      onSavePackage({
        name: packageName,
        type: packageType,
        functions: selectedPackage.functions,
        roles: packageRoles,
        id: selectedPackage.id,
        changed: true
      });
    }
  }

  return <><div className="pkg-right-panel" key={selectedPackage.name}>
    <div className="sdds-row sdds-u-p3 ">
      <div className="sdds-no-padding sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-4">
        <div className="form-panel">
          <div className="pkg-name-section">
            <div className="sdds-headline-05">Package Name*</div>
            <input className={`sdds-u-mt2 ${pkgNameError}`} type="text" placeholder={t("TS_temp:PackageName")} value={packageName} maxLength={255} onChange={onPackageNameChange} ref={pkgNameRef} />
          </div>
          <div className="pkg-type-section">
            <div className="sdds-headline-05">Package Type</div>

            <div className="sdds-u-mt2 sdds-radio-button-group">
              <div className="sdds-radio-item">
                <input className="sdds-form-input" type="radio" name="rb-pkg-type" id="rb-pkg-type-1" checked={packageType === 'Equipment'} value={"Equipment"} onChange={onPackageTypeChange} />
                <label className="sdds-form-label" htmlFor="rb-pkg-type-1">{t("TS_core:Equipment")}</label>
              </div>
              <div className="sdds-radio-item">
                <input className="sdds-form-input" type="radio" name="rb-pkg-type" id="rb-pkg-type-2" checked={packageType === 'Customer'} value={"Customer"} onChange={onPackageTypeChange} />
                <label className="sdds-form-label" htmlFor="rb-pkg-type-2">{t("TS_core:Customer")}</label>
              </div>
            </div>
          </div>
          <div className="sdds-headline-05">Roles with access to the package</div>
          <div className="sdds-u-mt2">
            {appRoles && appRoles.map((role) => {
              return <div role="button" key={role} className={`sdds-chip ${packageRoles && packageRoles.indexOf(role) > -1 ? "sdds-chip__active" : ""}`} onChange={() => { }} onClick={() => onRoleClick(role)} onKeyUp={() => onRoleClick(role)}>
                <span className="sdds-chip-text">{role}</span>
              </div>
            })}
          </div>
          <div className="btn-section">
            {!selectedPackage.id && <button className='sdds-btn sdds-btn-secondary sdds-btn-sm actionButton' onClick={() => onSaveClick()}  disabled={!(isDataChanged && packageRoles.length > 0 && hasPackageName)}><span>{t("TS_core:Save")}</span></button>}
            {selectedPackage.id && <button className='sdds-btn sdds-btn-secondary sdds-btn-md sdds-btn-fullbleed' onClick={() => onDeletePackage()}><span>{t("TS_core:Deregister")}</span></button>}
          </div>
        </div>
      </div>
      {selectedPackage && selectedPackage.id && <div className="sdds-no-padding sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8">
        <div className="title-row">
          <div className="sdds-headline-04">Troubleshooting functions in package</div>
          <button type='button' className='sdds-btn sdds-btn-sm sdds-btn-secondary' onClick={() => onShowAddFunctionModal()}>Add function</button>
        </div>

        <TabsComponent titles={[t("TS_core:Step2"), t("TS_core:Step3"), t("TS_core:Invalid")]} secondary rowCounts={[step2Functions.length, step3Functions.length, invalidFunctions.length]}>

          <div className="tab-panel" key={`step2-panel`}>
            {(!step2Functions || step2Functions.length === 0) && <div className="sdds-detail-03" key="no-func-step2">
            No Functions are added in Step 2.
            </div>}
            {step2Functions && step2Functions.map((docsFunc) => {
              return <FunctionComponent data={docsFunc as PackageFunctionType} onAddToPackageClick={onAddFunction} onDeleteFromPackageClick={onRemoveFunction} isReadonly key={(docsFunc as PackageFunctionType).id} />
            })}
          </div>

          <div className="tab-panel" key={`step3-panel`}>
            {(!step3Functions || step3Functions.length === 0) && <div className="sdds-detail-03" key="no-func-step3">
            No Functions are added in Step 3.
            </div>}
            {step3Functions && step3Functions.map((docsFunc) => {
              return <FunctionComponent data={docsFunc as PackageFunctionType} onAddToPackageClick={onAddFunction} onDeleteFromPackageClick={onRemoveFunction} isReadonly key={(docsFunc as PackageFunctionType).id} />
            })}
          </div>

          <div className="tab-panel" key={`invalid-panel`}>
            {(!invalidFunctions || invalidFunctions.length === 0) && <div className="sdds-detail-03" key="no-func-invalid">
            No Invalid Functions.
            </div>}
            {invalidFunctions && invalidFunctions.map((docsFunc) => {
              return <FunctionComponent data={docsFunc as PackageFunctionType} onAddToPackageClick={onAddFunction} onDeleteFromPackageClick={onRemoveFunction} isReadonly key={(docsFunc as PackageFunctionType).id} />
            })}
          </div>
        </TabsComponent>
      </div>}
    </div>
  </div>
    <AddFunctionModal onClose={() => setShowAddFunctionModal(false)} show={showAddFunctionModal} functionsList={availableFunctions} onAddFunction={onAddFunction} isApiLoading={isFunctionsListApiLoading} />
  </>;
}

export default PackageForm;