import React, { useEffect, useState } from 'react';
import './PackageManagement.scss';
import PackageListing from '../../components/PackageManagement/PackageListing';
import PackageForm from '../../components/PackageManagement/PackageForm';
import { fetchPackageList, selectPackage, fetchFunctionsList, addFunction, removeFromPackage, fetchAppRoles, sendDeletePackage, sendSavePackage } from "../../redux/store/packageManagement";
import { useAppSelector, useAppDispatch } from '../../redux/store/hooks';
import { Spinner } from '@cd/sdds-common-components-react';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { useTranslation } from 'react-i18next';


const PackageManagementPage = () => {
  const { t } = useTranslation();
  const packages: Array<PackageType> = useAppSelector<Array<PackageType>>(state => state.packageManagement.packages)
  const functionsList: Array<PackageFunctionType> = useAppSelector<Array<PackageFunctionType>>(state => state.packageManagement.functionsList)
  const isPakageListApiLoading: boolean = useAppSelector<boolean>(state => state.packageManagement.isPakageListApiLoading)
  const isPackageApiLoading: boolean = useAppSelector<boolean>(state => state.packageManagement.isPackageApiLoading)
  const isFunctionsListApiLoading: boolean = useAppSelector<boolean>(state => state.packageManagement.isFunctionsListApiLoading)
  const selectedPackage: PackageType = useAppSelector<PackageType>(state => state.packageManagement.selectedPackage)
  const appRoles: Array<string> = useAppSelector<Array<string>>(state => state.packageManagement.appRoles)
  const [showPkgDeleteConfirmDialog, setShowPkgDeleteConfirmDialog] = useState<boolean>(false);
  const [showRemoveFromPackgeConfirmDialog, setShowRemoveFromPackgeConfirmDialog] = useState<boolean>(false);

  const [functionId, setFunctionId] = useState<string>("");
  const dispatch = useAppDispatch();

  //on load fetch all guides & functions
  useEffect(() => {
    if (packages.length === 0) {
      dispatch(fetchPackageList());
    }
    if (appRoles.length === 0) {
      dispatch(fetchAppRoles())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowPkgDeleteConfirmDialog(false);
    setFunctionId("");
  }, [selectedPackage])

  const onSelectPackage = (selectedPackage: PackageType) => {
    dispatch(selectPackage(selectedPackage));
  }
  const onAddFunction = (payload: AddFunctionPayloadType) => {
    payload.packageId = selectedPackage.id
    dispatch(addFunction(payload));
  }
  const onRemoveFunction = (functionId: string) => {
    setFunctionId(functionId);
    setShowRemoveFromPackgeConfirmDialog(true);
  }
  const onConfirmRemoveFromPackage = () => {
    dispatch(removeFromPackage(selectedPackage.id, functionId));
    setShowRemoveFromPackgeConfirmDialog(false)
  }
  const onLoadFunctionsList = () => {
    dispatch(fetchFunctionsList());
  }
  const onSavePackage = (payload:PackageUpdatePayloadType) => {
    dispatch(sendSavePackage(payload));
  }
  const onConfirmDeletePackage = () => {
    dispatch(sendDeletePackage(selectedPackage.id));
    setShowPkgDeleteConfirmDialog(false)
  }
  return <>
    <div className="sdds-container-fluid  package-management-container ">
      <div className="sdds-row sdds-u-mt3">
        <div className="sdds-no-padding sdds-col-max-4 sdds-col-xxlg-4 sdds-col-xlg-4 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-4 sdds-col-xs-12">
          <PackageListing packages={packages} isPakageListApiLoading={isPakageListApiLoading} selectedPackage={selectedPackage} onSelectPackage={onSelectPackage} />
        </div>
        <div className="sdds-col-max-8 sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-8 sdds-col-sm-8 sdds-col-xs-8">
          {isPackageApiLoading && <Spinner />}
          {!isPackageApiLoading && selectedPackage &&
            <PackageForm
              selectedPackage={selectedPackage}
              allFunctions={functionsList}
              onAddFunction={onAddFunction}
              onRemoveFunction={onRemoveFunction}
              onDeletePackage={() => setShowPkgDeleteConfirmDialog(true)}
              onSavePackage={onSavePackage}
              onLoadFunctionsList={onLoadFunctionsList}
              isFunctionsListApiLoading={isFunctionsListApiLoading}
              appRoles={appRoles} />}
        </div>
      </div>
    </div>
    {showPkgDeleteConfirmDialog && <ConfirmationDialog
      onClose={() => setShowPkgDeleteConfirmDialog(false)}
      onAction={onConfirmDeletePackage}
      title={t("TS_core:ConfirmDeleteMessage")}
      confirmationMsg="Are you sure you want to delete the package?"
      primaryActionBtn={t("TS_core:Deregister")}
      secondaryActionBtn={t("TS_core:Cancel")} isDelete />}

    {showRemoveFromPackgeConfirmDialog && <ConfirmationDialog
      onClose={() => setShowRemoveFromPackgeConfirmDialog(false)}
      onAction={onConfirmRemoveFromPackage}
      title={t("TS_core:ConfirmDeleteMessage")}
      confirmationMsg="Are you sure you want to remove the Function from Package?"
      primaryActionBtn={t("TS_core:Deregister")}
      secondaryActionBtn={t("TS_core:Cancel")} isDelete />}
  </>;
}

export default PackageManagementPage;