 export const isValidUrl = (url: string): boolean =>{
    const regex = new RegExp('https://([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return regex.test(url);
};

// currently not using
/* export const isValidName = (name: string): boolean =>{
    const nameRegex = /^[a-zA-Z ]+$/
  return nameRegex.test(name)
}; 

export const isValidDescription = (description: string): boolean =>{
    const descriptionRegex = /^[a-zA-Z0-9 .,!"'-]+$/
  return descriptionRegex.test(description)
}; */

export const isValidGuid = (guid: string): boolean =>{
    const guidRegex = /^ *[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12} *$/i;
  return guidRegex.test(guid)
};