import { AxiosPromise } from 'axios';
import { csttFacadeApi } from '../csttFacadeApi';


const getAllGuides = (cultureCode: string):  AxiosPromise<TsFaultGuides> => csttFacadeApi.get(`/csttfacade/tsguide/v2/guides?cultureCode=${cultureCode}`);
const saveGuide = (guideDto: TsGuideDto) => csttFacadeApi.post(`/csttfacade/tsguide/v1/guides/`, guideDto);
const updateGuide = (guideDto: TsGuideDto) => csttFacadeApi.put(`/csttfacade/tsguide/v1/guides/dummyId`, guideDto);
const deleteGuide = (id: string):  AxiosPromise => csttFacadeApi.delete(`/csttfacade/tsguide/v1/guides/${id}`);
const getAllFunctions = (): AxiosPromise<TsFunction> => csttFacadeApi.get(`/csttfacade/tsguide/v1/functions/`);
const getAllPackages = (): AxiosPromise<PackageType> => csttFacadeApi.get(`/csttfacade/tsguide/v1/packages/`);

export { getAllGuides, saveGuide, deleteGuide, getAllFunctions, updateGuide,getAllPackages }