import { t } from "i18next"
import _ from "lodash"

interface FunctionParamValidationType {
  regex?: RegExp
  validatoinMessageResourceKey?: string
  helperTextResourceKey?: string
  inputType: "number" | "text" | "password" | undefined
  validationFunction?: (input: any) => string
} 
export const getValidationObjectByParamName = (parameterName: string): FunctionParamValidationType | undefined => {

  switch (parameterName) {
    case "mapversion":
      return {
        //eslint-disable-next-line
        regex: /^[0-9]+$/gm,
        validatoinMessageResourceKey: t("TS_temp:MapVersionIsInValid"),
        helperTextResourceKey: t("TS_temp:MapVersionInputHelp"),
        inputType: "text"
      }

    case "boundingboxcoordinates":
      return {
        //eslint-disable-next-line
        regex: /^([+-]?\d+\.\d+)[,]([+-]?\d+\.\d+)[,]([+-]?\d+\.\d+)[,]([+-]?\d+\.\d+)$/gm,
        validatoinMessageResourceKey: t("TS_temp:BoundingBoxCoordinatesIsInValid"),
        helperTextResourceKey: t("TS_temp:BoundingBoxCoordinatesInputHelp"),
        validationFunction: areMapCoordinatesValid,
        inputType: "text"
      }
    default:
      return undefined
  }
}

export const areMapCoordinatesValid = (coordinates: string) => {
  let errorMessageResourceKey: string = ""
  if (coordinates) {
    const mapPoints = coordinates.split(",")
    if (mapPoints.length < 4 || mapPoints.length > 4) {
      // Bounding Box Coordinates are not 4 values
      errorMessageResourceKey = t("TS_temp:BoundingBoxCoordinatesIsInValid")
    } else {
      const allDecimals = _.chain(mapPoints)
        .map((point) => {
          return !isNaN(parseFloat(point))
        })
        .reduce((sum, newValue) => {
          return sum && newValue
        }, true)
        .value();

      if (!allDecimals) {
        // Bounding Box Coordinates are not decimal Values
        errorMessageResourceKey = t("TS_temp:BoundingBoxCoordinatesIsInValid")
      } else {
        const southWestLatitiude = parseFloat(mapPoints[0]);
        const southWestLongitude = parseFloat(mapPoints[1]);
        const northEastLatitiude = parseFloat(mapPoints[2]);
        const northEastLongitude = parseFloat(mapPoints[3]);
        // check latitude range for both Southeest and Northeast values
        const isSouthWestLatInRange = southWestLatitiude >= -90.0 && southWestLatitiude <= 90.0 
        const isNorthEastLatInRange = northEastLatitiude >= -90.0 && northEastLatitiude <= 90.0 

        // check Longitude range for both Southeest and Northeast values
        const isSouthWestLngInRange = southWestLongitude >= -180.0 && southWestLongitude <= 180.0 
        const isNorthEastLngInRange = northEastLongitude >= -180.0 && northEastLongitude <= 180.0 

        if(!isSouthWestLatInRange || !isNorthEastLatInRange ){
          errorMessageResourceKey = t("TS_temp:BoundingBoxLatitudeNotInRange")
        }
        else if(!isSouthWestLngInRange || !isNorthEastLngInRange ){
          errorMessageResourceKey = t("TS_temp:BoundingBoxLatitudeNotInRange")
        }
        else if (southWestLatitiude > northEastLatitiude) {
          // The southwest latitude must be less than or equal to the northeast latitude.
          errorMessageResourceKey = t("TS_temp:BoundingBoxInValidLatitude")
        } else if (southWestLongitude > northEastLongitude)
          //The southwest longitude must be less than or equal to the northeast longitude.
          errorMessageResourceKey = t("TS_temp:BoundingBoxInValidLongitude")
      }
    }
  }
  return errorMessageResourceKey;
}