import React from 'react';
import './FaultCode.scss';

type FaultCodeProps = {
    code: string,
    description: string,
    showDetails: Function,
}
const FaultCode = (props: FaultCodeProps ) => {
    const { code, description, showDetails } = props;    
    return (
        <div className='fault-code-cell' role='button' onClick={showDetails} onKeyDown={showDetails}>
           <div className='fault-code-column'>{code}</div> 
           <div className='fault-description-column'>{description}</div> 
        </div>
    )
}
export default FaultCode;