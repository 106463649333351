import { t } from 'i18next';
import './GuidedTroubleshoutingTour.scss'

const generateTourStepsByRole = (userRole: string): any => {

    const equipmentOverviewLabel: string =  t("TS_core:EquipmentOverview");
    const serviceTroubleshootingLabel: string = t("TS_core:ServiceTroubleshooting");
    const packageManagementLabel: string = "Package Management";
    const functionManagementLabel: string = "Function Management";
    const faultGuideLabel: string = t("TS_temp:FaultGuide");
    const feedbackLabel: string = t("TS_core:SendFeedback");
    const equipmentOverviewDesciption: string = t("TS_core:W_EqipmentOverview");
    const serviceTroubleshootingDesciption: string = t("TS_core:W_ServiceTroubleshooting1");
    const packageManagementDesciption: string = "Create a new test package with a customized set of troubleshooting functions based on your use case. You can also view and edit existing packages.";
    const functionManagementDesciption: string = "Register a new troubleshooting function or view existing functions";
    const faultGuideDesciption: string = t("TS_core:W_FaultGuide1");
    const feedbackDescription: string = t("TS_core:W_Feedback");

    if(userRole === "Admin")
    {
        return([
            {
                title:<h4 className='tourTitle'>{equipmentOverviewLabel}</h4>,
                target:"#equipOverview",
                content:<p className ='pageTour'>{equipmentOverviewDesciption}</p>,
                disableBeacon: true,
                placement: "right-start" as const
            },
            {
                title:<h4 className='tourTitle'>{serviceTroubleshootingLabel}</h4>,
                target:"#serviceTroubleshooting_Tour_Id",
                content:<p className ='pageTour'>{serviceTroubleshootingDesciption}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className='tourTitle'>{packageManagementLabel}</h4>,
                target:"#packageManagement_Tour_Id",
                content:<p className ='pageTour'>{packageManagementDesciption}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className='tourTitle'>{functionManagementLabel}</h4>,
                target:"#functionManagement_Tour_Id",
                content:<p className ='pageTour'>{functionManagementDesciption}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className='tourTitle'>{faultGuideLabel}</h4>,
                target:"#faultGuide_Tour_Id",
                content:<p className ='pageTour'>{faultGuideDesciption}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className='tourTitle'>{feedbackLabel}</h4>,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
    else if(userRole === "GTS")
    {
        return([
            {
                title:<h4 className='tourTitle'>{equipmentOverviewLabel}</h4>,
                target:"#equipOverview",
                content:<p className ='pageTour'>{equipmentOverviewDesciption}</p>,
                disableBeacon: true,
                placement: "right-start" as const
            },
            {
                title:<h4 className='tourTitle'>{serviceTroubleshootingLabel}</h4>,
                target:"#serviceTroubleshooting_Tour_Id",
                content:<p className ='pageTour'>{serviceTroubleshootingDesciption}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className='tourTitle'>{faultGuideLabel}</h4>,
                target:"#faultGuide_Tour_Id",
                content:<p className ='pageTour'>{faultGuideDesciption}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className='tourTitle'>{feedbackLabel}</h4>,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
    else{
        return([
            {
                title:<h4 className='tourTitle'>{equipmentOverviewLabel}</h4>,
                target:"#equipOverview",
                content:<p className ='pageTour'>{equipmentOverviewDesciption}</p>,
                disableBeacon: true,
                placement: "right-start" as const
            },
            {
                title:<h4 className='tourTitle'>{serviceTroubleshootingLabel}</h4>,
                target:"#serviceTroubleshooting_Tour_Id",
                content:<p className ='pageTour'>{serviceTroubleshootingDesciption}</p>,
                disableBeacon: true
            },
            {
                title:<h4 className='tourTitle'>{feedbackLabel}</h4>,
                target:'#surveySliderBtn',
                content:<p className ='pageTour'>{feedbackDescription}</p>
            }
        ])
    }
}

export default generateTourStepsByRole;

export {
    generateTourStepsByRole
};
