import { Icons } from '@cd/sdds-common-components-react';
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import "./FirstStep.scss"


type PersonnelSearchProps = {
    personnelList: any,
    onPersonSelected(personnel: any): void,
    selectedPersonnel: any
    hasError: boolean
};

const PersonnelSearch = (props: PersonnelSearchProps) => {
    const { t } = useTranslation();
    const { personnelList, onPersonSelected, selectedPersonnel, hasError } = props
    const [Searchedvalues, setSearchedvalues] = useState(personnelList)
    const [prefix, setPrefix] = useState('')
    const [open, setOpen] = useState(false)
    const updatedSearch = (e: any) => {
        setSearchedvalues(personnelList?.filter((staff: any) => {
            return staff.firstName.toLowerCase().startsWith(e.toLowerCase())
        }))
    }
    const handleChange = (event: any) => {
        setPrefix(event.target.value)
        if (event.target.value === '') {
            onPersonSelected(null);
        }
    }
    const selectPerson = (person: any) => {
        if (person) {
            setPrefix(`${person.firstName} ${person.lastName}`)
            onPersonSelected(person)
        }
    }

    useEffect(() => {
        updatedSearch(prefix)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefix]);
    useEffect(() => {
        if (selectedPersonnel) {
            setPrefix(`${selectedPersonnel.firstName} ${selectedPersonnel.lastName}`)
        }
    }, [selectedPersonnel])

    return (
        <div className="personnelList"
            onClick={() => setOpen(open => !open)}
            onKeyPress={() => setOpen(open => !open)}
            role="button"
        >
            <input
                type="search"
                placeholder={t('TS_core:SearchByStaffName')}
                onChange={handleChange}
                value={prefix}
                className={hasError ? 'error' : ''}
            />
            {hasError && <div class={`sdds-form-textfield sdds-form-textfield-sm ${hasError ? 'sdds-form-textfield-error' : ""}`}>
                <div class="sdds-textfield-helper">
                    <div class="sdds-textfield-helper-error-state">
                        <Icons.InfoIcon />
                        <span slot="sdds-helper">{t("TS_core:ThisFieldIsRequired")}</span>
                    </div>
                </div>
            </div>}
            {personnelList && open &&
                <div className="suggest">
                    {Searchedvalues?.map((person: any, index: number) => {
                        return (
                            <div className="details"
                                onClick={() => { selectPerson(person) }}
                                onKeyPress={() => selectPerson(person)}
                                role="button"
                                key={`personnel-${index}`}
                            >
                                <div className="name">{person.firstName} {person.lastName}</div>
                                <div className="customer"><span>{t("TS_core:Customer")}</span> {person.customer.name}</div>
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}

export default PersonnelSearch;