import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { getTsFunction } from '../../adapters/TsGuide';

type DoCSFunctionButtonProps = {
  onClick: Function
  functionId: string
}

const DoCSFunctionButton = ({ onClick, functionId }: DoCSFunctionButtonProps) => {
  const { t } = useTranslation();
  const [functionObj, setFunctionObj] = useState<TsFunction>(null);

  useEffect(() => {
    getTsFunction(functionId)
      .then((functionResponse) => {
        setFunctionObj(functionResponse.data);
      });
  }, [functionId])
const name = functionObj?.name || "";
return <>
  <button className='sdds-btn sdds-btn-secondary sdds-btn-sm' onClick={() => onClick()}>{t(name)}</button>
</>;
}

export default DoCSFunctionButton