import React, { useEffect, useState } from 'react'
//import Stepper from '../../components/Steppers/Steppers';
import StepTabs from '../../components/StepTabs';
import FirstStep from './FirstStep';
import STTestStep from '../STTestStep';

import { useAppDispatch, useAppSelector } from '../../redux/store/hooks';
import { updateNextStep, fetchPersonalTest, selectingPersonnal, fetchFunctionsByPackage, reStartAllChecksExecution } from "../../redux/store/tsGuide";
import { RootState } from '../../redux/store';
import _ from 'lodash';
import { CONNTECTD_MAP_FUNCTION_PARAMS, SUPPORTED_FUNCTION_PARAMS } from '../../helpers/constants';

import "./FirstStep.scss";
import "./st-steps.scss"

const ServiceTroubleshooting = () => {
    const [staffSelected, setStaffSelected] = useState<boolean>(true)
    const { universalSearch, tsGuide }: RootState = useAppSelector<RootState>(state => state)
    const { selectedPackage, functions, selectedPersonnel, step, areAnyTestsRunning, sessionsInfo, step2TestLists, isLoadingFunctionParameters, apiError, apiNoData, step3TestLists, personnelsList } = tsGuide
    const { selectedVehicle, selectedCommunicator } = universalSearch;
    const dispatch = useAppDispatch()
    const requirePersonelSearch = selectedPackage?.name.toLowerCase() === 'remote heater control';
    const [allfunctionParameters, setFunctionParameters] = useState<any>({})
    const [packageSpecificParams, setPkgSepcificParams] = useState<any>([])
    const [parameterAreValid, setParameterIsValid] = useState<any>({})
    const [disableNext, setDisableNext] = useState<boolean>(false);

    useEffect(() => {
        if (selectedPackage !== null) {
            dispatch(fetchFunctionsByPackage(selectedPackage?.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPackage]);

    useEffect(() => {
        let params = []
        if (functions && functions.length > 0) {
            params = _.chain(functions)
                .map("contextSpec")
                .map("functionParameters")
                .flatten()
                .remove((key) => {
                    return !SUPPORTED_FUNCTION_PARAMS.includes(key.toLowerCase())
                })
                .value();
        }
        setPkgSepcificParams(params);
    }, [functions])

    useEffect(() => {
        if (functions && functions.length > 0) {
            const functionNeedPersonnel = _.filter(functions, { name: 'CheckUserAccess' });
            if (functionNeedPersonnel && functionNeedPersonnel.length > 0) {
                dispatch(fetchPersonalTest(selectedVehicle?.externalCustomerReference as string))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [functions, selectedVehicle]);

    const onPersonnelSelected = (personnel: any) => {
        setStaffSelected(true);
        dispatch(selectingPersonnal(personnel))
    }

    const warning = sessionsInfo.warning
    const failed = sessionsInfo.error

    const excelDownload = {
        vin: universalSearch.selectedVehicle?.identification as string,
        selectedPackage: selectedPackage as PackageType,
        data: step2TestLists
    }

    const nextButtonClick = () => {
        if (requirePersonelSearch && !selectedPersonnel) {
            setStaffSelected(false);
        } else {
            if (_.includes(packageSpecificParams, CONNTECTD_MAP_FUNCTION_PARAMS)) {
                CONNTECTD_MAP_FUNCTION_PARAMS.forEach((cmap_param) => {
                    // if(allfunctionParameters[cmap_param])
                })
            }
            dispatch(updateNextStep(step + 1));
        }
    }
    const reRunAllTests = () => {
        dispatch(reStartAllChecksExecution(step, allfunctionParameters));
    }
    useEffect(() => {
        const eqParams = {
            'vin': selectedVehicle?.identification,
            'chassisnumber': selectedVehicle?.chassisNumber,
            'externalcustomerreference': selectedVehicle?.externalCustomerReference,
            'externalequipmentreference': selectedVehicle?.externalEquipmentReference,
            'externalstaffreference': selectedPersonnel?.externalStaffReference,
            'communicationdevice': selectedCommunicator?.rtcType || ''
        }

        const params = _.clone(allfunctionParameters)
        const newEqparams = _.clone(eqParams)
        let functionParams = _.merge(params, newEqparams)

        if (!_.includes(functionParams, packageSpecificParams)) {
            const pkgParams: any = {};
            _.each(packageSpecificParams, (key) => {
                pkgParams[key] = ""
            })
            functionParams = _.merge(functionParams, pkgParams)
        }
        setFunctionParameters(functionParams) // ToDo; error here

        //eslint-disable-next-line
    }, [selectedVehicle, selectedCommunicator, selectedPersonnel, packageSpecificParams])

    const onFunctionParameterChange = (parameterName: string, value: string, isValid: boolean) => {
        const params = _.clone(allfunctionParameters)
        const functionParams = _.merge(params, { [parameterName]: value })
        const areAllIsValid = _.merge(parameterAreValid, { [parameterName]: isValid })
        const areAllValid = Object.values(areAllIsValid).reduce((prev, currentValue) => {
            return prev && currentValue
        }, true)
        setParameterIsValid(areAllIsValid);
        setFunctionParameters(functionParams)
        setDisableNext(!areAllValid)
    }
    return (
        <div>
            {/* <Stepper step={step}/> */}
            <div className="sdds-u-mt3"></div>
            <StepTabs
                step={step}
                maxStep={3}
                next={nextButtonClick}
                prev={() => dispatch(updateNextStep(step - 1))}
                runAll={() => reRunAllTests()}
                warning={warning}
                failed={failed}
                excelDownload={excelDownload}
                areTestsRunnging={areAnyTestsRunning}
                disableNext={isLoadingFunctionParameters || disableNext}
            >
                {(() => {
                    switch (step) {
                        case 1:
                            return <FirstStep
                                storeData={universalSearch}
                                requirePersonelSearch={requirePersonelSearch}
                                personnelSearch={personnelsList}
                                selectingPersonnel={onPersonnelSelected}
                                selectedPersonnel={selectedPersonnel}
                                hasError={requirePersonelSearch && !staffSelected}
                                functionsParams={packageSpecificParams}
                                onFunctionParameterChange={onFunctionParameterChange}
                                isLoadingParameters={isLoadingFunctionParameters}
                            />;
                        case 2:
                            return <STTestStep
                                packageId={selectedPackage?.id as string}
                                eqParams={allfunctionParameters}
                                testStep={2}
                                testList={step2TestLists}
                                packageFunctionsList={functions}
                                apiError={apiError}
                                apiNoData={apiNoData}
                                autoRun={true}
                            />;
                        case 3:
                            return <STTestStep
                                packageId={selectedPackage?.id as string}
                                eqParams={allfunctionParameters}
                                testStep={3}
                                testList={step3TestLists}
                                apiError={apiError}
                                apiNoData={apiNoData}
                                packageFunctionsList={functions}
                                autoRun={false}
                            />;
                    }
                })()}

            </StepTabs>
        </div>
    )
}

export default ServiceTroubleshooting;