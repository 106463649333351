import React, { MouseEventHandler } from 'react'
type WarningIconPropsType = {
  onMouseOver?: MouseEventHandler
  color:string
}

const WarningSvg = ({ color, onMouseOver }: WarningIconPropsType) => {
  return (
    <svg onMouseOver={onMouseOver} className='warning-svg-icon' width="32" height="32" fill="none" color={color}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M14.803 16.47V13h2.376v3.47l-.352 4.295h-1.672l-.352-4.295Zm-.053 5.632h2.5v2.394h-2.5v-2.394Z" fill="currentColor" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16 2a1 1 0 0 1 .887.539l13 25A1 1 0 0 1 29 29H3a1 1 0 0 1-.887-1.461l13-25A1 1 0 0 1 16 2ZM4.647 27h22.706L16 5.168 4.647 27Z" fill="currentColor" />
    </svg>
  );
}
export default WarningSvg;