import { CustomModal } from "@cd/sdds-common-components-react"
import { memo } from "react";
import { useTranslation } from "react-i18next"


type AddConfirmationModalPropTypes = {
  onClose: () => void;
  onAction: () => void;
  newFunction: { baseUri: string, functionId: string }
};

const AddConfirmationModal = ({ onClose, onAction, newFunction }: AddConfirmationModalPropTypes) => {
  const { t } = useTranslation();

  return <CustomModal
    id="add-function-confirm-modal"
    show={true}
    title={t("TS_temp:RegisterYourDoCSFunction")}
    handleClose={onClose}
    size="sm"
    actions={(
      <>
        <button type="button" className="sdds-btn sdds-btn-md sdds-btn-secondary sdds-u-mb2" onClick={onClose} >{t("TS_core:No")}</button>
        <button type="button" className={`sdds-btn sdds-btn-md sdds-btn-primary sdds-u-mb2`} onClick={onAction}>{t("TS_core:Yes")}</button>
      </>
    )}
  >
    <div className="sdds-headline-05">Confirm Prerequisites</div>
    <ul className="sdds-body-03">
      <li>The resource containing the endpoints supports<strong> Federation Gateway External</strong>.</li>
      <li>The resource has been connected by the client id<strong> cstt_facade</strong> in Federation Gateway portal.</li>
      <li>The resource support following HTTP Request endpoints</li>
      <ul>
        <li><strong>GET</strong> {newFunction.baseUri + newFunction.functionId}</li>
        <li><strong className="sdds-text-green-300">POST</strong> {newFunction.baseUri}</li>
      </ul>
    </ul>
    </CustomModal >
}

export default memo(AddConfirmationModal);