
  //read required equipment values
  const getEqValue = (param: string, receivedParams: ReceivedParamsType, paramType: string, functionParameterValues:any) => {
    if (paramType === 'assert') {
        if (receivedParams.assertParameters.hasOwnProperty(param)) return receivedParams.assertParameters[param];
    }
    else {
        if (receivedParams.functionParameters.hasOwnProperty(param)) return receivedParams.functionParameters[param];
    }

    if (functionParameterValues.hasOwnProperty(param)) return functionParameterValues[param];
    return '';
};

export const constructEqParametersObject = (assertParameters: Array<string>, functionParameters: Array<string>, functionId: string|undefined, receivedParams: ReceivedParamsType,functionParameterValues:{}) => {
    let eqParametersObject: eqParametrsObjectType = {
        functionId: '',
        assertParameters: {},
        functionParameters: {}
    };
    let assertParams: any = {};
    let functionParams: any = {};

    assertParameters.forEach(param => {
        assertParams[param] = getEqValue(param, receivedParams, 'assert', functionParameterValues);
    });
    functionParameters.forEach(param => {
        functionParams[param] = getEqValue(param, receivedParams, 'function', functionParameterValues);
    });

    eqParametersObject.assertParameters = assertParams;
    eqParametersObject.functionParameters = functionParams;
    eqParametersObject.functionId = functionId as string

    return eqParametersObject;
}

export const getEqParams = (test: PackageFunctionType, functionParameterValues:{}) => {
    let assertParameters = test.contextSpec?.assertParameters || [];
    let functionParameters = test.contextSpec?.functionParameters || [];

    //collect assert & function params received
    let receivedParams: ReceivedParamsType = {
        assertParameters: test.assertParameters || {},
        functionParameters: test.functionParameters || {},
    };
    return constructEqParametersObject(assertParameters, functionParameters,  (test?.id || test?.contextSpec?.id ), receivedParams,functionParameterValues);
}