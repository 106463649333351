import { createSlice } from '@reduxjs/toolkit'
import { groupBy } from "lodash";
import { functionsList, deleteFunction, addFunction } from '../../adapters/FunctionManagement';


const initialState: FunctionManagementState = {
    functionsList: [],
    functionsListLoading: false,
    functionDeleted: '',
    isLoading: false,
    hasError: false,
    errorMessage: "",
    isSaveSuccess: false,
}
// Slice    
const slice = createSlice({
    name: 'functionManagement',
    initialState: initialState,
    reducers: {
        updateSearchedGuide: (state, action) => {
            const groupedList = groupBy(action.payload, (value) => value.baseUri);
            state.functionsList = groupedList
        },
        updateSearchedGuideLoading: (state, action) => {
            state.functionsListLoading = action.payload
        },
        updatefunctionDeleted: (state, action) => {
            state.functionDeleted = action.payload
        },
        updatefunctionAdd: (state, action) => {
            state.functionDeleted = action.payload
        },
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        updateIsSaveSuccess: (state, action) => {
            state.isSaveSuccess = action.payload
        },
        updateHasError: (state, action) => {
            debugger;
            state.errorMessage = action.payload.hasError ? action.payload.errorMessage : ""
            state.hasError = action.payload.hasError;
        },
    },
});
export default slice.reducer

// Actions
const { updateSearchedGuide, updateSearchedGuideLoading, updatefunctionDeleted, updateHasError, updateIsLoading, updateIsSaveSuccess } = slice.actions

export const fetchFunctionManagementList = () => async (dispatch: any) => {
    dispatch(updateSearchedGuideLoading(true))
    try {
        const res = await functionsList()
        dispatch(updateSearchedGuide(res.data));
        dispatch(updateSearchedGuideLoading(false))

    } catch (e) {
        return console.error(e);
    }
}
export const deletingFunctionManagement = (id: string) => async (dispatch: any) => {
    dispatch(updatefunctionDeleted(''))
    try {
        await deleteFunction(id)
        dispatch(fetchFunctionManagementList())

    } catch (e: any) {
        dispatch(updateHasError({ hasError: true, errorMessage: e.message }));
        return console.error(e);
    }
}
export const addingFunctionManagement = (data: any) => async (dispatch: any) => {
    dispatch(updateHasError({ hasError: false }));
    dispatch(updateIsLoading(true));
    dispatch(updateIsSaveSuccess(false));
    try {
        await addFunction(data)
        dispatch(updateIsSaveSuccess(true));
        dispatch(fetchFunctionManagementList())
    } catch (e: any) {
        if (e.response && [200, 201].indexOf(e.response.status) === -1) {
            dispatch(updateHasError({ hasError: true, errorMessage: e.response.data.errorMessageCode }));
        } else {
            dispatch(updateHasError({ hasError: true, errorMessage: e.message }));
        }
    }finally{
        dispatch(updateIsLoading(false));
        dispatch(updateIsSaveSuccess(false));
    }
}