import { AuthenticatedTemplate, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';

import { InteractionType } from "@azure/msal-browser";
import './App.scss';
import { callCsttFacadeApis } from './adapters/UserAdapter';
import { loginRequest } from './authConfig';
import { Spinner, UserSettings, MOpinionFeedback } from '@cd/sdds-common-components-react';
import { useTranslation } from 'react-i18next';
import { RBACProvider, RBACWrapper } from 'react-simple-rbac';
import LandingPage from './pages/Landing';
import { getSupportedRolesForSections } from './helpers/sectionRoleMapper';
import FaultGuideManagement from './pages/FaultGuideManagement';
import FunctionManagement from './pages/FunctionManagement';
import PackageManagementPage from './pages/PackageManagement';
import { UserContext } from './appContext';
import Index from './pages';

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const [loggedInProfileInfo, setLoggedInProfileInfo] = useState<ProfileInformationType>({} as ProfileInformationType);
  const [isDeveloper, setIsDeveloper] = useState<boolean>(false);

  const { i18n } = useTranslation();
  const [apiResponse, setApiResponse] = useState({});
  const [apiCalled, setApiCalled] = useState<string>('getProfileInfo');

  const [profileInfoLoader, setProfileInfoLoader] = useState<AsyncStateType>({ loading: true, error: false })

  useEffect(() => {
    if (isAuthenticated) {
      setApiCalled('getProfileInfo');
      callCsttFacadeApis(apiCalled, setApiResponse, setProfileInfoLoader, []);
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if(profileInfoLoader.error){
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
    }
  }, [profileInfoLoader]);


  useEffect(() => {
    setLoggedInProfileInfo(apiResponse as ProfileInformationType)
  }, [apiResponse]);

  useEffect(() => {
    i18n.changeLanguage(loggedInProfileInfo?.settings?.language || 'en-GB');
    if (loggedInProfileInfo.role === 'Admin' && loggedInProfileInfo.permissions?.developer) { // to enable special permissions for developers
      setIsDeveloper(true)
    } else
      setIsDeveloper(false)

  }, [loggedInProfileInfo, i18n]);


  return (
    <div className="App">
      <sdds-theme global="true" />
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
      </MsalAuthenticationTemplate>
      <AuthenticatedTemplate>
        {profileInfoLoader.loading && (<Spinner size='lg' />)}
        {(!(profileInfoLoader.error || profileInfoLoader.loading)) && (
          <RBACProvider roles={[loggedInProfileInfo.role]} >
            <UserContext.Provider value={loggedInProfileInfo}>
              <Router>
                  <Routes>
                    <Route path='/' element={<Index />} >
                      <Route path="service-troubleshooting/:idParam" element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"]}><LandingPage developerAccess={isDeveloper} loggedInProfileInfo={loggedInProfileInfo} /></RBACWrapper></>} />
                      <Route path="service-troubleshooting" element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"]}><LandingPage developerAccess={isDeveloper} loggedInProfileInfo={loggedInProfileInfo} /></RBACWrapper></>} />
                      <Route index element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"]}><LandingPage developerAccess={isDeveloper} loggedInProfileInfo={loggedInProfileInfo} /></RBACWrapper></>} />
                      <Route path="user-settings" element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"]}><UserSettings
                        userDetails={loggedInProfileInfo}
                        setLoggedInProfileInfo={setLoggedInProfileInfo} />
                      </RBACWrapper></>} />
                      <Route path="fault-guide-management" element={<><RBACWrapper oneOf requiredRoles={getSupportedRolesForSections('Fault Guide Management')}><FaultGuideManagement developerAccess={isDeveloper} /></RBACWrapper></>} />
                      <Route path="function-management" element={<><RBACWrapper oneOf requiredRoles={getSupportedRolesForSections('Function Management')}><FunctionManagement /></RBACWrapper></>} />
                      <Route path="package-management" element={<><RBACWrapper oneOf requiredRoles={getSupportedRolesForSections('Package Management')}><PackageManagementPage /></RBACWrapper></>} />
                    </Route>
                  </Routes>
              </Router>
            </UserContext.Provider>
          </RBACProvider>)}
        {profileInfoLoader.error && (<p>{'Profile Info Api : ' + profileInfoLoader.errorMessage}</p>)}
      </AuthenticatedTemplate>
      <MOpinionFeedback />
    </div>
  );
};
export default App;
