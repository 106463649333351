import { TdsTextField } from "@scania/tegel-react";
import type { TdsTextFieldCustomEvent } from '@scania/tegel';
import { useState } from "react";
import type { JSX } from '@scania/tegel';
import { debounce } from "lodash";

interface BoundingBoxFieldType extends JSX.TdsTextField {
  parameterName: string
  onValueChange?: (parameterName: string, newValue: string, isVaild: boolean) => void
  validationPattren?: RegExp
  pattrenValidationMsg?: string
  required?: string
  helperMessage?: string
  customValidationFunction?: (input: string) => string

}
type TdsTextFieldState = "default" | "error" | "success" | undefined;

const TegelTextField = (props: BoundingBoxFieldType) => {
  const { parameterName, onValueChange, required, validationPattren, pattrenValidationMsg, helperMessage, customValidationFunction } = props;
  const [validationError, setValidationError] = useState<string>("")
  const [fieldState, setFieldState] = useState<TdsTextFieldState>(undefined)

  const validateInput = (inputEl: HTMLTdsTextFieldElement) => {
    const input = inputEl.value.replace(" ", "");
    let isInValid = false
    let validationMsg = ""
    // if there is an
    if ((!input || input === "") && required && required !== "") {
      isInValid = true
      validationMsg = required || "This field is required!"
    }

    if (!isInValid && input && validationPattren) {
      const pattrenRegex = new RegExp(validationPattren);
      isInValid = !pattrenRegex.test(input)
      if (isInValid) {
        validationMsg = pattrenValidationMsg || "The input failed validation!"
      }
    }
    if (!isInValid && input && customValidationFunction) {
      const customValidationMsg = customValidationFunction(input)
      if (customValidationMsg && customValidationMsg !== "") {
        validationMsg = customValidationMsg
      }
    }
    if (!input && !isInValid) {
      setFieldState("default");
    } else if(input && !isInValid) {
      setFieldState("success");
    }
   
    if(validationMsg !== ""){
      setFocus(inputEl)
      setFieldState("error")
    }
    onValueChange && onValueChange(parameterName, input, !isInValid);
    setValidationError(validationMsg)
  }

  const setFocus = (tdsTxtField: HTMLTdsTextFieldElement) => {
    setTimeout(() => {
      const inputEl = tdsTxtField.querySelectorAll('.text-field-input')[0] as HTMLInputElement
      inputEl.focus();
    }, 200);


  }
  const onTdsInput = (e: TdsTextFieldCustomEvent<InputEvent>) => {
    validateInput(e.target)
  }

  return <TdsTextField {...props}
    id={"txt" + parameterName}
    name={parameterName}
    size="md"
    label={props.label || parameterName}
    onTdsInput={debounce(onTdsInput, 300)}
    helper={validationError !== "" ? validationError : helperMessage || ""}
    state={fieldState}
    labelPosition="outside"
  />
}

export default TegelTextField