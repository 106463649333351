

import React, { useState } from 'react'
import './TabsComponent.scss'

type TabsPropsType = {
  titles: Array<string>
  rowCounts: Array<number>
  children?: any
  secondary?: boolean
}

export const TabsComponent = ({ titles, children, secondary, rowCounts }: TabsPropsType) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  return <>
    <div className={`sdds-inline-tabs ${secondary ? "sdds-inline-tabs-secondary" : ""}`} key="tab-container">
      <nav className="sdds-inline-tabs-header" key="tab-header">
        <div className="sdds-inline-tabs-wrapper" key="tab-wrapper">
          {titles.map((tabTitle, index: number) => {
            return <>
              <button key={tabTitle} className={`sdds-inline-tabs--tab ${index === activeTab ? "sdds-inline-tabs--tab__active" : " "}`} onClick={() => { setActiveTab(index) }}>
                <span key={`${tabTitle}-text`}>{tabTitle}</span>
                <div className="tab-badges" key={`${tabTitle}-badge`}>
                  <div class="sdds-badges sdds-badges-default">
                    <div class="sdds-badges-text">{rowCounts[index]}</div>
                  </div>
                </div>
              </button>
            </>
          })}
        </div>
      </nav>
      <div className="sdds-inline-tabs-main">
        {children && children.map((child: any, index: number) => {
          return <>
            {index === activeTab && child}
          </>
        })}
      </div>
    </div >
  </>
}

export default TabsComponent;